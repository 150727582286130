import types from '../actionTypes';
import documentService from '../../services/document.service';

export const createDocument = (documentIndex, document) => (dispatch, getState) => {
    if (getState().documentData.isCreating) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_CREATE_REQUEST,
    });

    return documentService.createDocument(documentIndex, document)
        .then(() => {
            dispatch({
                type: types.DOCUMENT_CREATE_SUCCESS,
                payload: {document},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const chat = (documentIndex, query, historyId, prevHistoryId) => (dispatch, getState) => {
    if (getState().documentData.isSearching) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_CHAT_REQUEST,
    });

    return documentService.chat(documentIndex, query, historyId, prevHistoryId)
        .then((documentAnswers) => {
            dispatch({
                type: types.DOCUMENT_CHAT_SUCCESS,
                payload: {documentAnswers},
            });
            return documentAnswers;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_CHAT_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const searchDocument = (documentIndex, query, historyId, fullAnswers) => (dispatch, getState) => {
    if (getState().documentData.isSearching) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_SEARCH_REQUEST,
    });

    return documentService.searchDocument(documentIndex, query, historyId, fullAnswers)
        .then((documentAnswers) => {
            dispatch({
                type: types.DOCUMENT_SEARCH_SUCCESS,
                payload: {documentAnswers},
            });
            return documentAnswers;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_SEARCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchDocuments = (documentIndex) => (dispatch, getState) => {
    if (getState().documentData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_FETCH_REQUEST,
    });

    return documentService.getDocuments(documentIndex)
        .then((documents) => {
            dispatch({
                type: types.DOCUMENT_FETCH_SUCCESS,
                payload: { documents },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const fetchDocumentById = (documentIndex, documentId) => (dispatch, getState) => {
    if (getState().documentData.isFetchingSingle) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_FETCH_SINGLE_REQUEST,
    });

    return documentService.getDocumentSingle(documentIndex, documentId)
        .then((document) => {
            dispatch({
                type: types.DOCUMENT_FETCH_SINGLE_SUCCESS,
                payload: { document },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_FETCH_SINGLE_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const deleteDocument = (documentIndex, documentId) => (dispatch, getState) => {
    if (getState().documentData.isDeleting) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_DELETE_REQUEST,
    });

    return documentService.deleteDocument(documentIndex, documentId)
        .then(() => {
            dispatch({
                type: types.DOCUMENT_DELETE_SUCCESS,
                payload: { documentId },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_DELETE_FAIL,
                payload: { error },
            });

            throw error;
        });
}

export const updateDocument = (documentIndex, documentId, document) => (dispatch, getState) => {
    if (getState().documentData.isUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_UPDATE_REQUEST,
    });

    return documentService.updateDocument(documentIndex, documentId, document)
        .then(() => {
            dispatch({
                type: types.DOCUMENT_UPDATE_SUCCESS,
                payload: { document },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_UPDATE_FAIL,
                payload: { error },
            });

            throw error;
        });
}
