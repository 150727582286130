import React, { useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { createDocument, fetchDocumentById, fetchDocuments, searchDocument } from '../../redux/actions/document';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Divider, FormControl, Grid, MenuItem, Paper, Select } from '@mui/material';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Index from "../../components/Loading";
import Markdown from "react-markdown";
import { useTheme } from "@emotion/react";
import documentService from "../../services/document.service";

const DocumentDetail = ({
    fetchDocumentById,
    document,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { index_name, document_id } = useParams();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        fetchDocumentById(index_name, document_id).then(() => {
            setLoading(false);
        });
    }, [index_name, document_id]);

    const startDownload = () => {
        documentService.getDocumentPrepareDownload(index_name, document_id).then(data => {
            const key = data.key;
            const downlaodUrl = documentService.getDocumentDownloadURI(index_name, document_id, key);
            console.log("downlaodUrl", downlaodUrl);
            window.open(downlaodUrl, '_blank', 'noopener,noreferrer');
        });
    };

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={1}>
                        <Button
                            sx={{
                                color: theme.palette.primary.contrastText,
                                borderColor: theme.palette.secondary.main,
                                '&:hover': {
                                    color: theme.palette.primary.main,
                                    borderColor: theme.palette.secondary.main,
                                }
                            }}
                            variant="outlined"
                            disabled={location.key === 'default'}
                            onClick={() => navigate(-1)}>
                            {t("BACK")}
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            {t('DOCUMENT_SUMMARY')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={2}>
                        {!loading && document?.url && <Button
                            sx={{
                                color: theme.palette.primary.contrastText,
                                borderColor: theme.palette.secondary.main,
                                '&:hover': {
                                    color: theme.palette.primary.main,
                                    borderColor: theme.palette.secondary.main,
                                }
                            }}
                            variant="outlined"
                            onClick={() => window.open(document.url, '_blank', 'noopener,noreferrer')}>
                            {t("VISIT_WEBPAGE")}
                        </Button>}
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            sx={{
                                color: theme.palette.primary.contrastText,
                                borderColor: theme.palette.secondary.main,
                                '&:hover': {
                                    color: theme.palette.primary.main,
                                    borderColor: theme.palette.secondary.main,
                                }
                            }}
                            variant="outlined"
                            disabled={loading}
                            onClick={startDownload}>
                            {t("DOWNLOAD")}
                        </Button>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    {loading && (<Grid item xs={12}><Index /></Grid>)}

                    {!loading && (
                        <>
                            <Grid item xs={2.7}></Grid>
                            <Grid item xs={0.3}><AccountCircleIcon sx={{ color: theme.palette.secondary.main }} /></Grid>
                            <Grid item xs={1} sx={{ textAlign: "left" }}>
                                <Typography variant="body1">
                                    {document.user_updated.first_name} {document.user_updated.last_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="body1">
                                    {new Date(document.updated_at).toLocaleDateString()}
                                </Typography>
                            </Grid>
                            <Grid item xs={2.5}></Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <Select
                                        value={1}
                                        disabled
                                        sx={{ height: 32 }}
                                    >
                                        <MenuItem value={1}>{t("SHORT_SUMMARY")}</MenuItem>
                                        <MenuItem value={2}>{t("MEDIUM_SUMMARY")}</MenuItem>
                                        <MenuItem value={3}>{t("LONG_SUMMARY")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2.5}></Grid>

                            <Grid item xs={2}></Grid>
                            <Grid item xs={8} style={{ maxHeight: '80vh', overflow: 'auto', marginTop: 4 }}>
                                <Paper elevation={3} sx={{ textAlign: "left", borderRadius: 0, margin: 2, marginBottom: 4 }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="h5" component="h2" gutterBottom>
                                                {document.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={10}>

                                            <Typography variant="body1" gutterBottom>
                                                <Markdown>{document.summary}</Markdown>
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={12}></Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </Container>
    );
}

DocumentDetail.propTypes = {
    user: PropTypes.object,
};

DocumentDetail.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    document: store.documentData.documentSingle,
    documents: store.documentData.documents,
    documentAnswers: store.documentData.documentAnswers,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createDocument,
    searchDocument,
    fetchDocuments,
    fetchDocumentById,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentDetail);