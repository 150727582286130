import httpService from './http.service';


const getDocumentIndexes = () => httpService
    .get(`/document_index/`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getDocumentIndexByName = (name) => httpService
    .get(`/document_index/?name=${name}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getFavoriteDocumentIndexes = () => httpService
    .get(`/document_index/favorite`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getArchivedDocumentIndexes = () => httpService
    .get(`/document_index/archive`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const createDocumentIndex = (documentIndex) => httpService
    .post(`/document_index/`, documentIndex)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const updateDocumentIndex = (documentIndex) => httpService
    .put(`/document_index/${documentIndex.id}`, documentIndex)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));


const deleteDocumentIndex = (documentIndexId) => httpService
    .remove(`/document_index/${documentIndexId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const addUserToDocumentIndex = (documentIndexId, userId, accessRights) => httpService
    .post(`/document_index/${documentIndexId}/users/${userId}`, {access_rights: accessRights})
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const removeUserFromDocumentIndex = (documentIndexId, userId) => httpService
    .remove(`/document_index/${documentIndexId}/users/${userId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const changeUserAccessRights = (documentIndexId, userId, accessRights) => httpService
    .put(`/document_index/${documentIndexId}/users/${userId}`, {access_rights: accessRights})
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const getDocumentIndexUsers = (documentIndexId) => httpService
    .get(`/document_index/${documentIndexId}/users`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const addDocumentIndexFavorite = (documentIndexId) => httpService
    .put(`/document_index/${documentIndexId}/favorite`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const removeDocumentIndexFavorite = (documentIndexId) => httpService
    .remove(`/document_index/${documentIndexId}/favorite`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const archiveDocumentIndex = (documentIndexId) => httpService
    .put(`/document_index/${documentIndexId}/archive`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const unarchiveDocumentIndex = (documentIndexId) => httpService
    .remove(`/document_index/${documentIndexId}/archive`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

export default {
    getDocumentIndexByName,
    getDocumentIndexes,
    getFavoriteDocumentIndexes,
    getArchivedDocumentIndexes,
    createDocumentIndex,
    updateDocumentIndex,
    deleteDocumentIndex,
    addUserToDocumentIndex,
    removeUserFromDocumentIndex,
    changeUserAccessRights,
    getDocumentIndexUsers,
    addDocumentIndexFavorite,
    removeDocumentIndexFavorite,
    archiveDocumentIndex,
    unarchiveDocumentIndex,
};
