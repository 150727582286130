import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { chat, createDocument, fetchDocuments, searchDocument, deleteDocument } from '../../redux/actions/document';
import { fetchDocumentIndex } from '../../redux/actions/documentindex';
import documentService from '../../services/document.service';
import { useParams, useSearchParams } from "react-router-dom";
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Grow,
    InputAdornment,
    OutlinedInput,
    Switch,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material';
import AnswerCard from './AnswerCard';
import Typography from '@mui/material/Typography';
import Loading from "../../components/Loading";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { showHistory } from "../../redux/actions/global";
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import DocumentUpdate from "../../components/DocumentUpdate";
import { styled } from "@mui/system";
import { isIdontKnowAnswer } from "../../utils";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useInterval } from "react-use";
import { useDispatch } from "react-redux";
import types from '../../redux/actionTypes';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { ChatAnswers } from "./ChatAnswers";
import { DocumentCard } from "./DocumentCard";

export const Icon = styled('img')(({ theme }) => ({}));

export const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.default,
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: "90vw",
        fontSize: theme.typography.pxToRem(12),
        //border: `1px solid ${theme.palette.background.grey400}`,
        boxShadow: `0 0 20px 0.5px ${theme.palette.background.grey400}`,
    },
}));

export const SourceText = styled(Typography)(({ theme }) => ({
    //border: `1px solid ${theme.palette.background.grey200}`,
    //borderRadius: 12,
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
}));

const ToggleButtonSelected = styled(ToggleButton)(({ theme }) => ({
    border: '0px solid',
    borderTopRightRadius: '15px !important',
    borderBottomRightRadius: '15px !important',
    borderTopLeftRadius: '15px !important',
    borderBottomLeftRadius: '15px !important',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.background.grey,
    transition: 'background-color 0.4s ease',
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: theme.palette.primary.third,
        borderTopRightRadius: '15px !important',
        borderBottomRightRadius: '15px !important',
        borderTopLeftRadius: '15px !important',
        borderBottomLeftRadius: '15px !important',

    },

}));

const ToggleButtonSelectedAsk = styled(ToggleButtonSelected)(({ theme }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: theme.palette.primary.third,

    },

}));

const ToggleButtonSelectedChat = styled(ToggleButtonSelected)(({ theme }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "black",
        backgroundColor: theme.palette.secondary.main,
    },
}));

const Answer = ({ documentAnswers, threshold, documents }) => {


    return (
        <Grid container spacing={1} item xs={12} sx={{
            maxHeight: '85vh', overflow: 'auto', marginTop: 4, marginRight: 4, paddingBottom: 8,
        }}>

            {
                documentAnswers.answers.map((answer) => {
                    if (isIdontKnowAnswer(answer)) {
                        return null;
                    } else {
                        return <Grid item xs={12}>
                            <AnswerCard
                                answer={answer}
                                threshold={threshold}/>
                        </Grid>
                    }
                })

            }
        </Grid>

    )
}

const ASK_DOCUMENTS = "askDocuments";
const ASK = "ask";
const CHAT = "chat";

export const ORIGIN_HISTORY_ID = "OHID";

const SearchPage = ({
    createDocument,
    searchDocument,
    fetchDocuments,
    chat,
    documents,
    documentAnswers,
    documentChatAnswers,
    history,
    showHistory,
    documentIndex,
    fetchDocumentIndex,
    deleteDocument,
    queryHistory,
}) => {
    const { index_name } = useParams();
    const dispatch = useDispatch();

    const [queryParameters] = useSearchParams()

    const [loadedIndex, setLoadedIndex] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
    const [query, setQuery] = React.useState('');
    const [error, setError] = React.useState('');
    const [chatHistoryId, setChatHistoryId] = React.useState(null);
    const [chatHistory, setChatHistory] = React.useState([]);
    const [askHistoryId, setAskHistoryId] = React.useState(null);

    const [searchType, setSearchType] = React.useState(ASK);

    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [indexConfigModel, setIndexConfigModel] = useState({
        open: false,
        index: {},
        isNew: true
    });

    const [threshold, setThreshold] = useState(0.5);

    useEffect(() => {
        if (documentAnswers && documentAnswers.answers) {
            const idontknow = documentAnswers.answers.find(a => isIdontKnowAnswer(a));
            if (idontknow) {
                setThreshold(idontknow.init_parameters.score);
            } else {
                setThreshold(0.5);
            }
            setAskHistoryId(documentAnswers.query_history_id);
        }
    }, [documentAnswers]);

    const loadHistory = () => {
        if (queryParameters.get(ORIGIN_HISTORY_ID)) {
            const hist = queryHistory.filter(h => h.id == queryParameters.get(ORIGIN_HISTORY_ID)).find(_ => true);

            if (hist && hist.query_type === CHAT) {
                const queryAnswers = [...hist.query_answers];
                queryAnswers.sort((a, b) => new Date(a.query_date) > new Date(b.query_date));

                const newChatHistory = queryAnswers.map(qa => {
                    return {
                        query: qa.answer.query,
                        answer: qa.answer.answers[0].answer,
                        resources: qa.answer.resources,
                    }
                });
                setChatHistory(newChatHistory);
            } else if (hist) {
                dispatch({
                    type: types.DOCUMENT_SEARCH_SUCCESS,
                    payload: { documentAnswers: hist.query_answers[0].answer },
                });
                setQuery(hist.query);
            }

            if (hist) {
                setSearchType(hist.query_type);
            }
        }
    };

    useEffect(() => {
        if (loadedIndex !== index_name || !loaded ||
            (chatHistoryId !== queryParameters.get(ORIGIN_HISTORY_ID) &&
                askHistoryId !== queryParameters.get(ORIGIN_HISTORY_ID))) {
            const indexPromise = fetchDocumentIndex(index_name);
            const documentsPromise = fetchDocuments(index_name);

            Promise.all([indexPromise, documentsPromise]).finally(() => {
                setIsLoadingDocuments(false);
                setLoaded(true);
                setLoadedIndex(index_name);
                setChatHistoryId(null);
                setAskHistoryId(null);
                setQuery("");
                console.log("queryParameters", queryParameters);
                if (queryParameters.get(ORIGIN_HISTORY_ID)) {
                    loadHistory();
                } else {
                    setChatHistory([]);
                }
            });
        }
    }, [loaded, index_name, loadedIndex, queryParameters]);

    useInterval(
        () => {
            if (searchType === ASK && !isLoadingSearch && !isLoadingDocuments && (!query || query === "")) {
                fetchDocuments(index_name).catch(() => {
                    console.log("Could not refresh documents.");
                });
            }
        },
        loaded ? 10000 : null
    );

    const search = (localSearchType) => {
        if (query && query.length > 4) {
            setError('')
            setIsLoadingSearch(true);
            searchDocument(index_name, query, askHistoryId, localSearchType === ASK).then(() => {
                setIsLoadingSearch(false);
            }).catch((error) => {
                setIsLoadingSearch(false);
                if (error?.response?.data?.error) {
                    setError(error.response.data.error);
                } else {
                    setError(t('UNEXPECTED_ERROR'));
                }
            });
        } else {
            setError(t('SEARCH_QUERY_TOO_SHORT'));
        }
    }

    const chatInner = () => {
        if (query && query.length > 4) {
            setError('')
            setIsLoadingSearch(true);


            const sse = documentService.chatStreaming(index_name, query, chatHistoryId, queryParameters.get(ORIGIN_HISTORY_ID),
                (ev, sse) => {
                    const evJson = JSON.parse(ev.data);
                    console.log("evJson", evJson);

                    setChatHistory(origChatHistory => {
                        const cht = [...origChatHistory];

                        if (evJson.type == "INTERMEDIATE") {
                            const lastEntry = cht[cht.length - 1];
                            lastEntry.answer += evJson.data;
                        } else if (evJson.type == "ERROR") {
                            const chatRow = {
                                query: query,
                                answer: t("UNEXPECTED_ERROR"),
                                resources: [],
                            };
                            cht[cht.length - 1] = chatRow;
                            sse.close();
                            setIsLoadingSearch(false);
                            setQuery('');
                        } else if (evJson.type == "FINAL") {
                            const chatRow = {
                                query: evJson.data.query,
                                answer: evJson.data.answers && evJson.data.answers.length > 0 ? evJson.data.answers[0].answer : "N/A",
                                resources: evJson.data.resources,
                            };
                            cht[cht.length - 1] = chatRow;
                            sse.close();
                            setIsLoadingSearch(false);
                            setChatHistoryId(evJson.data.query_history_id);
                            setQuery('');
                        }

                        return cht;
                    });
                },
                (errorEv, sse) => {
                    console.log("errorEv", errorEv);

                    if (isLoadingSearch) {
                        setIsLoadingSearch(false);
                        if (errorEv?.response?.data?.error) {
                            setError(errorEv.response.data.error);
                        } else {
                            setError(t('UNEXPECTED_ERROR'));
                        }

                        setChatHistory(origChatHistory => {
                            const cht = [...origChatHistory];
                            const chatRow = { ...cht[cht.length - 1] };
                            chatRow.answer = t("UNABLE_RESPOND");
                            cht[cht.length - 1] = chatRow;
                            return cht;
                        });

                        sse.close();
                    }
                },
                (openEv, sse) => {
                    console.log("openEv", openEv);

                    if (openEv.status == 200) {
                        const chatRow = {
                            query: query,
                            answer: ""
                        };
                        const cht = [...chatHistory];
                        cht.push(chatRow);
                        setChatHistory(cht);
                    }
                });
        } else {
            setError(t('SEARCH_QUERY_TOO_SHORT'));
        }
    }

    const handleCloseModal = () => {
        setIndexConfigModel({
            open: false,
            index: {},
            isNew: true
        });
        setLoaded(false);
    };

    const handleNewIndex = () => {
        setIndexConfigModel({ isNew: true, group: {}, open: true });
    }
    const handleIndexUpdate = (updatedIndex) => {
        let index = { ...updatedIndex }
        setIndexConfigModel({ isNew: false, index: index, open: true, });

    };

    const handleChange = (event, newAlignment) => {
        if (newAlignment) {
            setSearchType(newAlignment);
        } else if (searchType === ASK || searchType === ASK_DOCUMENTS) {
            setSearchType(CHAT);
        } else {
            setSearchType(ASK);
        }
    };


    return (

        <Grid container spacing={2}>
            <DocumentUpdate
                indexConfigModel={indexConfigModel}
                createDocument={createDocument}
                handleClose={handleCloseModal}
                saving={saving}
                indexName={index_name}

            />

            <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography align="left" variant='h4'
                    style={{ paddingRight: 20 }}>{index_name}</Typography>
            </Grid>
            <Grid item xs={2}>
                <ToggleButtonGroup
                    sx={{
                        border: '4px solid',
                        height: 40,
                        backgroundColor: 'background.grey',
                        borderColor: 'background.grey',
                        borderRadius: 15
                    }}
                    fullWidth={true}
                    value={searchType === ASK_DOCUMENTS ? ASK : searchType}
                    exclusive
                    onChange={handleChange}
                    aria-label="Question"
                    size="small"
                >
                    <ToggleButtonSelectedAsk sx={{ marginRight: '10px' }} value={ASK}><HelpOutlineRoundedIcon sx={{ paddingRight: '5px' }} /> {`Ask`}
                    </ToggleButtonSelectedAsk>
                    <ToggleButtonSelectedChat value={CHAT}>{`Chat`}
                        <QuestionAnswerRoundedIcon sx={{ paddingLeft: '5px' }} />
                    </ToggleButtonSelectedChat>
                </ToggleButtonGroup>

            </Grid>
            <Grid item xs={5}>
                <Box display="flex" justifyContent="flex-end">
                    <Button variant="contained" endIcon={<ScheduleIcon />}
                        sx={{
                            width: '150px', margin: 1, color: 'primary.contrastText', bgColor: 'background.grey',
                        }}
                        onClick={() => showHistory(!history)}>{t('HISTORY')}</Button>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />
            </Grid>
            <Grid item xs={6}>
                {(searchType === ASK || searchType === ASK_DOCUMENTS) && <FormControl fullWidth={true}>
                    <OutlinedInput
                        endAdornment={(
                            <InputAdornment position="start">
                                <Search onClick={() => search()} />
                            </InputAdornment>
                        )}
                        sx={{
                            borderWidth: 0,
                            backgroundColor: "background.grey",
                            borderRadius: 30,
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderWidth: "0px !important",
                            }
                        }}
                        fullWidth={true}
                        type="search"
                        value={query}
                        onChange={(event) => {
                            setQuery(event.target.value);


                        }}
                        disabled={isLoadingDocuments || isLoadingSearch}
                        onKeyDown={(ev) => {
                            if (ev.key === 'Enter') {
                                // Do code here
                                ev.preventDefault();
                                search(searchType);
                            }
                        }}
                        variant="outlined" />
                    <FormHelperText id="component-helper-text">
                        {error}
                    </FormHelperText>
                </FormControl>}

            </Grid>
            <Grid item xs={2}>
                {(searchType === ASK || searchType === ASK_DOCUMENTS) &&
                    <Tooltip title={t("FULL_ANSWERS_TOOLTIP")}>
                        <FormControlLabel
                            control={<Switch
                                checked={searchType === ASK}
                                onChange={() => {
                                    const newSearchType = searchType === ASK ? ASK_DOCUMENTS : ASK;
                                    setSearchType(newSearchType);
                                    if (query !== "")
                                        search(newSearchType);

                                }}
                                color="secondary"
                                disabled={isLoadingDocuments || isLoadingSearch} />} label={t("FULL_ANSWERS")} />
                    </Tooltip>
                }
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={3}>
                {(searchType === ASK || searchType === ASK_DOCUMENTS) &&
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            sx={{
                                width: '200px'
                            }}
                            color="secondary"
                            variant="contained"
                            disabled={isLoadingDocuments || documentIndex?.archived}
                            onClick={() => handleNewIndex()}>
                            Add document
                        </Button>
                    </Box>}
            </Grid>
            {(searchType === ASK || searchType === ASK_DOCUMENTS) && (isLoadingDocuments || isLoadingSearch) &&
                <Grid item xs={12} style={{ padding: 'auto' }}><Loading /></Grid>}


            {searchType === ASK && !isLoadingSearch && query !== "" && !!documentAnswers && documentAnswers.answers &&
                <Answer documentAnswers={documentAnswers} threshold={threshold} documents={documents} />
            }

            {searchType === CHAT &&
                <ChatAnswers chatHistory={chatHistory} isLoadingSearch={isLoadingSearch} query={query} />
            }


            {(searchType === ASK || searchType === ASK_DOCUMENTS) && !isLoadingSearch && !isLoadingDocuments && (!query || query === "") &&
                <Grid item xs={12}>
                    <Grid container spacing={4} style={{ maxHeight: '80vh', overflow: 'auto', marginTop: 0, paddingRight: 4 }}
                        direction={"row"}>
                        {documents.map((document, numberIndex) =>
                            <Grow
                                in={true}
                                style={{ transformOrigin: '0 0 0' }}
                                timeout={Math.min(numberIndex * 100, 1000)}
                            >
                                <Grid item xs={2}>
                                    <DocumentCard document={document}
                                        index_name={index_name}
                                        deleteDocument={deleteDocument}
                                    />
                                </Grid>
                            </Grow>)}

                    </Grid>
                </Grid>
            }
            {searchType === ASK_DOCUMENTS && !isLoadingSearch && !isLoadingDocuments &&
                documentAnswers && documentAnswers.full_documents && documentAnswers.full_documents.length > 0 &&
                <Grid item xs={12}>
                    <Grid container spacing={4} style={{ maxHeight: '80vh', overflow: 'auto', marginTop: 0, paddingRight: 4 }}
                        direction={"row"}>
                        {documentAnswers.full_documents.map((document) => <Grid item xs={2}>
                            <DocumentCard document={document}
                                index_name={index_name}
                                deleteDocument={deleteDocument}
                            />
                        </Grid>)}

                    </Grid>
                </Grid>
            }
            {
                searchType === CHAT &&
                <OutlinedInput
                    multiline
                    variant="outlined"
                    endAdornment={(
                        <InputAdornment sx={{ cursor: "pointer" }} position="start">
                            <SendRoundedIcon onClick={() => chatInner()} />
                        </InputAdornment>
                    )}
                    onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                            // Do code here
                            ev.preventDefault();
                            chatInner();
                        }
                    }}
                    autoFocus
                    rows={4}
                    fullWidth
                    id="description"
                    value={isLoadingSearch ? "" : query}
                    onChange={(event) => setQuery(event.target.value)}
                    sx={{
                        borderWidth: 0,
                        backgroundColor: "background.grey",
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderWidth: 0,
                        },
                        height: '100px',
                        width: 'calc(100% - 400px)',
                        margin: 'auto',
                        position: 'absolute',
                        bottom: 40,
                        left: 300
                    }}
                />

            }
        </Grid>

    );
}

SearchPage.propTypes = {
    user: PropTypes.object,
};

SearchPage.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    documents: store.documentData.documents,
    documentAnswers: store.documentData.documentAnswers,
    documentChatAnswers: store.documentData.documentChatAnswers,
    documentIndex: store.documentIndexData.documentIndexSingle,
    history: store.globalData.history,
    queryHistory: store.queryHistoryData.queryHistory,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createDocument,
    searchDocument,
    chat,
    fetchDocuments,
    showHistory,
    fetchDocumentIndex,
    deleteDocument,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SearchPage);
