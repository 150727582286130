import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import {Divider, FormHelperText, IconButton, InputBase, OutlinedInput, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import {styled} from "@mui/system";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {CirclePicker} from "react-color";

const CloseButton = styled(IconButton)(({theme}) => ({
    left: 10,
    color: (theme) => theme.palette.primary.contrastText

}));

const FormFields = styled('div')(({theme}) => ({
    height: '100%',
    width: '100%',
    flexDirection: 'row',

    alignItems: 'flex-start',
    paddingTop: '50px',

}));

function IndexUpdate(props) {
    const {
        handleClose,
        saveIndex,
        indexConfigModel,
        saving,
    } = props;


    const {t} = useTranslation();

    const [updatedIndex, setUpdatedIndex] = useState({});

    const [error, setError] = useState('');

    useEffect(() => {
        if (indexConfigModel.index) {
            setUpdatedIndex(indexConfigModel.index);

        }
    }, [indexConfigModel]);

    const isValid = (isNew) => {
        let ret = true;
        const error = {};
        if (!updatedIndex.index_name || updatedIndex.index_name.trim().length === 0) {
            error.index_name = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        if (!updatedIndex.color) {
            error.color = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        setError(error);
        console.log('error', error);    
        return ret;
    }


    const handleChange = (name) => (event) => {
        if (event && event.target) {
            setUpdatedIndex({...updatedIndex, [name]: event.target.value});
        } else {
            setUpdatedIndex({...updatedIndex, [name]: event});
        }
    }


    return (

        <Dialog
            maxWidth="md"
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    height: '80%',
                    margin: 1,
                },
            }}
            open={indexConfigModel.open}
            onClose={handleClose}
        >

            <DialogTitle sx={{
                height: '40px',
                paddingLeft: 5,
                paddingRight: 5,
                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                width: '100%',
            }}>
                <>
                    <Typography variant='h7'
                                sx={{color: (theme) => theme.palette.background.contrastText}}>{indexConfigModel.isNew ? t('CREATE') : t('EDIT')}</Typography>
                </>


                <CloseButton aria-label="delete" size="small" onClick={handleClose}>
                    <ClearIcon fontSize="medium"/>

                </CloseButton>

            </DialogTitle>


            <DialogContent>

                <FormFields>
                    <form autoComplete='off'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Divider sx={{width: '100%', borderBottomWidth: 2}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography align="left" variant='body2'


                                            sx={{paddingBottom: 5}}>{t('INDEX_NAME')}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <OutlinedInput variant="outlined"
                                               autoFocus
                                               error={!!error.index_name}
                                               helperText={error.index_name}
                                               disabled={!indexConfigModel.isNew}
                                               fullWidth
                                               id="index_name"
                                               value={updatedIndex.index_name || ''}
                                               onChange={handleChange('index_name', false)}
                                               sx={{
                                                   borderWidth: 0,
                                                   backgroundColor: "background.grey",
                                                   '& .MuiOutlinedInput-notchedOutline': {
                                                       borderWidth: 0,
                                                   }
                                               }}
                                />
                                {error.index_name && <FormHelperText error={true}>{error.index_name}</FormHelperText>}
                            </Grid>

                            <Grid item xs={6}>
                                <Typography align="left" variant='body2'


                                            sx={{paddingBottom: 5}}>{t('INDEX_DESCRIPTION')}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <OutlinedInput
                                    multiline
                                    variant="outlined"
                                    rows={4}
                                    error={error.description}
                                    helperText={error.description}
                                    fullWidth
                                    id="description"
                                    value={updatedIndex.description || ''}
                                    onChange={handleChange('description', false)}
                                    sx={{
                                        borderWidth: 0,
                                        backgroundColor: "background.grey",
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderWidth: 0,
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{width: '100%', borderBottomWidth: 2}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography align="left" variant='body2'


                                            sx={{paddingBottom: 5}}>{t('COLOR')}</Typography>
                            </Grid>
                            <Grid item xs={6}>

                                <CirclePicker
                                    color={updatedIndex.color}
                                    onChangeComplete={handleChange("color")}
                                    colors={[
                                        '#FFD1DC', '#FFECB3', '#D0F0C0', '#B2CCFF', '#FFC3A0', '#C7CEEA',
                                        '#FFB6C1', '#FFF59D', '#C8E6C9', '#9FA8DA', '#FFAB91', '#B39DDB',
                                        '#FF8A80', '#FFF176', '#A5D6A7', '#90CAF9', '#FF8F00', '#81D4FA',
                                        '#E57373', '#FFEB3B', '#81C784', '#64B5F6', '#FF6F00', '#4FC3F7'
                                      ]}
                                />
                                {error.color && <FormHelperText error={true}>{error.color}</FormHelperText>}

                            </Grid>
                        </Grid>
                    </form>
                </FormFields>

            </DialogContent>

            <DialogActions
                sx={{
                    padding: 0, height: 100, width: '100%',
                }}>
                <Button
                    sx={{
                        width: '150px', margin: 1, color: 'primary.contrastText', backgroundColor: 'background.grey',
                    }}
                    variant="contained"
                    onClick={handleClose}
                >{t('CANCEL')} </Button>


                <Button
                    sx={{
                        width: '150px', margin: 1,
                        backgroundColor: 'primary.third',
                        color: 'primary.selection',
                    }}
                    variant="contained"
                    disabled={saving}
                    loading={saving}
                    onClick={() => {
                        const ret = isValid(indexConfigModel.isNew);
                        if (ret) {
                            saveIndex(updatedIndex);
                        }
                    }}
                > {t('SAVE')}</Button>

            </DialogActions>


        </Dialog>

    );
}

IndexUpdate.propTypes = {};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IndexUpdate);


