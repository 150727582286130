import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingAllList: false,
    isFetchingActiveList: false,
    isUpdating: false,
    isAdding: false,
    errors: '',
    users: null,
    activeUsers: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.USERS_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                users: action.payload.users,
            };
        case types.USERS_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };
        case types.USERS_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };

        case types.USERS_ACTIVE_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingActiveList: false,
                activeUsers: action.payload.users,
            };
        case types.USERS_ACTIVE_FETCH_FAIL:
            return {
                ...state,
                isFetchingActiveList: false,
                errors: action.payload.error,
            };
        case types.USERS_ACTIVE_FETCH_REQUEST:
            return {
                ...state,
                isFetchingActiveList: true,
            };

        case types.USERS_ADD_SUCCESS:
            return {
                ...state,
                isAdding: false,
                users: [...state.users, action.payload.user],
            };
        case types.USERS_ADD_FAIL:
            return {
                ...state,
                isAdding: false,
                errors: action.payload.error,
            };
        case types.USERS_ADD_REQUEST:
            return {
                ...state,
                isAdding: true,
            };

        case types.USERS_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                users: state.users.map((user) => {
                    if (user.id === action.payload.user.id) {
                        return action.payload.user;
                    }
                    return user;
                }),
            };
        case types.USERS_UPDATE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };
        case types.USERS_UPDATE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };

        case types.USERS_CHANGE_ROLE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                users: state.users.map((user) => {
                    if (user.id === action.payload.user.id) {
                        return action.payload.user;
                    }
                    return user;
                }),
            };
        case types.USERS_CHANGE_ROLE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };
        case types.USERS_CHANGE_ROLE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };

        case types.USERS_FETCH_SINGLE_SUCCESS:
            return {
                ...state,
                isFetchingSingle: false,
                user: action.payload.user,
            };
        case types.USERS_FETCH_SINGLE_FAIL:
            return {
                ...state,
                isFetchingSingle: false,
                errors: action.payload.error,
            };
        case types.USERS_FETCH_SINGLE_REQUEST:
            return {
                ...state,
                isFetchingSingle: true,
            };

        case types.USERS_ACTIVATE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                users: state.users.map((user) => {
                    if (user.id === action.payload.user.id) {
                        return action.payload.user;
                    }
                    return user;
                }),
            };
        case types.USERS_ACTIVATE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };
        case types.USERS_ACTIVATE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };

        case types.USERS_DEACTIVATE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                users: state.users.map((user) => {
                    if (user.id === action.payload.user.id) {
                        return action.payload.user;
                    }
                    return user;
                }),
            };
        case types.USERS_DEACTIVATE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };
        case types.USERS_DEACTIVATE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };

        default:
            return state;
    }
}

