import types from '../actionTypes';
import queryHistoryService from '../../services/queyhistory.service';

export const fetchQueryHistory = () => (dispatch, getState) => {
    if (getState().queryHistoryData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.QUERY_HISTORY_FETCH_REQUEST,
    });

    return queryHistoryService.getQueryHistory()
        .then((queryHistory) => {
            dispatch({
                type: types.QUERY_HISTORY_FETCH_SUCCESS,
                payload: { queryHistory: queryHistory },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.QUERY_HISTORY_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
}