import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Box, Grid, Tab, Tabs, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProfilePage from './Profile';
import TeamPage from './Team';
import { USER_ROLE_ADMINISTRATOR } from "../../utils/ListItem";

const SETTINGS_TAB_GENERAL_ID = 0;
const SETTINGS_TAB_PROFILE_ID = 1;
const SETTINGS_TAB_TEAM_ID = 2;
const SETTINGS_TAB_SUBSCRIPTION_DETAILS_ID = 3;
const SETTINGS_TAB_ABOUT_ID = 4;

const StyledTab = styled(Tab)(({ theme }) => {
    return {
        color: theme.palette.grey400,
    };
});

const StyledTabs = styled(Tabs)(({ theme }) => {
    return {
        '& .Mui-selected': {
            color: `${theme.palette.primary.third} !important`,
            backgroundColor: theme.palette.primary.light,
            borderRadius: 15,
        }
    };
});

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          children
        )}
      </div>
    );
  }

const SettingsPage = ({
    user,
}) => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState(SETTINGS_TAB_PROFILE_ID);

    return (
        <Grid container spacing={2} sx={{ paddingTop: 2 }}>
            <Grid item xs={12}>
                <Typography variant="h3" sx={{ textAlign: "start" }}>
                    {t("SETTINGS")}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <StyledTabs
                    value={currentTab}
                    onChange={(_event, newValue) => setCurrentTab(newValue)}
                    TabIndicatorProps={{
                        style: {
                            display: 'none'
                        }
                    }}>
                    <StyledTab label={t("PROFILE_TAB")} id={SETTINGS_TAB_PROFILE_ID} value={SETTINGS_TAB_PROFILE_ID} />
                    {user.role === USER_ROLE_ADMINISTRATOR && <StyledTab label={t("TEAM_TAB")} id={SETTINGS_TAB_TEAM_ID} value={SETTINGS_TAB_TEAM_ID} />}
                </StyledTabs>
                <TabPanel value={currentTab} index={SETTINGS_TAB_PROFILE_ID}>
                    <ProfilePage />
                </TabPanel>
                <TabPanel value={currentTab} index={SETTINGS_TAB_TEAM_ID}>
                    <TeamPage />
                </TabPanel>
            </Grid>
        </Grid>
    )
}

SettingsPage.propTypes = {
    user: PropTypes.object,
};

SettingsPage.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsPage);
