import types from '../actionTypes';
import documentIndexService from '../../services/documentindex.service';


export const updateDocumentIndex = (documentIndex) => (dispatch, getState) => {
    if (getState().documentIndexData.isUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_UPDATE_REQUEST,
    });

    return documentIndexService.updateDocumentIndex(documentIndex)
        .then(() => {
            dispatch({
                type: types.DOCUMENT_INDEX_UPDATE_SUCCESS,
                payload: {documentIndex},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const createDocumentIndex = (documentIndex) => (dispatch, getState) => {
    if (getState().documentIndexData.isCreating) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_CREATE_REQUEST,
    });

    return documentIndexService.createDocumentIndex(documentIndex)
        .then(() => {
            dispatch({
                type: types.DOCUMENT_INDEX_CREATE_SUCCESS,
                payload: {documentIndex},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const deleteDocumentIndex = (documentIndexId) => (dispatch, getState) => {
    if (getState().documentIndexData.isDeleting) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_DELETE_REQUEST,
    });

    return documentIndexService.deleteDocumentIndex(documentIndexId)
        .then(() => {
            dispatch({
                type: types.DOCUMENT_INDEX_DELETE_SUCCESS,
                payload: {documentIndexId},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_DELETE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchDocumentIndexes = () => (dispatch, getState) => {
    if (getState().documentIndexData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_FETCH_REQUEST,
    });

    return documentIndexService.getDocumentIndexes()
        .then((documentIndexes) => {
            dispatch({
                type: types.DOCUMENT_INDEX_FETCH_SUCCESS,
                payload: { documentIndexes },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const fetchDocumentIndex = (documentIndexName) => (dispatch, getState) => {
    if (getState().documentIndexData.isFetchingSingle) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_FETCH_SINGLE_REQUEST,
    });

    return documentIndexService.getDocumentIndexByName(documentIndexName)
        .then((documentIndex) => {
            dispatch({
                type: types.DOCUMENT_INDEX_FETCH_SINGLE_SUCCESS,
                payload: { documentIndex },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_FETCH_SINGLE_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const fetchFavoriteDocumentIndexes = () => (dispatch, getState) => {
    if (getState().documentIndexData.isFetchingFavoritesList) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_FETCH_FAVORITE_REQUEST,
    });

    return documentIndexService.getFavoriteDocumentIndexes()
        .then((documentIndexes) => {
            dispatch({
                type: types.DOCUMENT_INDEX_FETCH_FAVORITE_SUCCESS,
                payload: { documentIndexes },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_FETCH_FAVORITE_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const fetchArchivedDocumentIndexes = () => (dispatch, getState) => {
    if (getState().documentIndexData.isFetchingArchivedList) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_FETCH_ARCHIVED_REQUEST,
    });

    return documentIndexService.getArchivedDocumentIndexes()
        .then((documentIndexes) => {
            dispatch({
                type: types.DOCUMENT_INDEX_FETCH_ARCHIVED_SUCCESS,
                payload: { documentIndexes },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_FETCH_ARCHIVED_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const fetchDocumentIndexUsers = (documentIndexId) => (dispatch, getState) => {
    if (getState().documentIndexData.isFetchingUserList) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_USERS_FETCH_REQUEST,
    });

    return documentIndexService.getDocumentIndexUsers(documentIndexId)
        .then((documentIndexUsers) => {
            dispatch({
                type: types.DOCUMENT_INDEX_USERS_FETCH_SUCCESS,
                payload: { documentIndexId, documentIndexUsers },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_USERS_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const addUserToDocumentIndex = (documentIndexId, userId, accessRights) => (dispatch, getState) => {
    if (getState().documentIndexData.isAddingOrModifyingUser) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_USER_ADD_REQUEST,
    });

    return documentIndexService.addUserToDocumentIndex(documentIndexId, userId, accessRights)
        .then((accessRight) => {
            dispatch({
                type: types.DOCUMENT_INDEX_USER_ADD_SUCCESS,
                payload: { accessRight },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_USER_ADD_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const removeUserFromDocumentIndex = (documentIndexId, userId) => (dispatch, getState) => {
    if (getState().documentIndexData.isAddingOrModifyingUser) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_USER_REMOVE_REQUEST,
    });

    return documentIndexService.removeUserFromDocumentIndex(documentIndexId, userId)
        .then(() => {
            dispatch({
                type: types.DOCUMENT_INDEX_USER_REMOVE_SUCCESS,
                payload: { documentIndexId, userId },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_USER_REMOVE_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const changeUserAccessRights = (documentIndexId, userId, accessRights) => (dispatch, getState) => {
    if (getState().documentIndexData.isAddingOrModifyingUser) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_USER_ACCESS_RIGHTS_CHANGE_REQUEST,
    });

    return documentIndexService.changeUserAccessRights(documentIndexId, userId, accessRights)
        .then((accessRight) => {
            dispatch({
                type: types.DOCUMENT_INDEX_USER_ACCESS_RIGHTS_CHANGE_SUCCESS,
                payload: { accessRight },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_USER_ACCESS_RIGHTS_CHANGE_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const addDocumentIndexFavorite = (documentIndexId) => (dispatch, getState) => {
    if (getState().documentIndexData.isUpdatingFavorite) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_FAVORITE_ADD_REQUEST,
    });

    return documentIndexService.addDocumentIndexFavorite(documentIndexId)
        .then(() => {
            dispatch({
                type: types.DOCUMENT_INDEX_FAVORITE_ADD_SUCCESS,
                payload: { documentIndexId },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_FAVORITE_ADD_FAIL,
                payload: { error },
            });

            throw error;
        });
}

export const removeDocumentIndexFavorite = (documentIndexId) => (dispatch, getState) => {
    if (getState().documentIndexData.isUpdatingFavorite) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_FAVORITE_REMOVE_REQUEST,
    });

    return documentIndexService.removeDocumentIndexFavorite(documentIndexId)
        .then(() => {
            dispatch({
                type: types.DOCUMENT_INDEX_FAVORITE_REMOVE_SUCCESS,
                payload: { documentIndexId },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_FAVORITE_REMOVE_FAIL,
                payload: { error },
            });

            throw error;
        });
}

export const archiveDocumentIndex = (documentIndexId) => (dispatch, getState) => {
    if (getState().documentIndexData.isUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_ARCHIVE_REQUEST,
    });

    return documentIndexService.archiveDocumentIndex(documentIndexId)
        .then((documentIndex) => {
            dispatch({
                type: types.DOCUMENT_INDEX_ARCHIVE_SUCCESS,
                payload: { documentIndex },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_ARCHIVE_FAIL,
                payload: { error },
            });

            throw error;
        });
}

export const unarchiveDocumentIndex = (documentIndexId) => (dispatch, getState) => {
    if (getState().documentIndexData.isUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.DOCUMENT_INDEX_UNARCHIVE_REQUEST,
    });

    return documentIndexService.unarchiveDocumentIndex(documentIndexId)
        .then((documentIndex) => {
            dispatch({
                type: types.DOCUMENT_INDEX_UNARCHIVE_SUCCESS,
                payload: { documentIndex },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.DOCUMENT_INDEX_UNARCHIVE_FAIL,
                payload: { error },
            });

            throw error;
        });
}
