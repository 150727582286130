export default {
    // Set user info
    SET_USER_INFO: 'SET_USER_INFO',
    // Logout
    LOG_OUT: 'LOG_OUT',

    // Fetch code list
    ALL_CODE_FETCH_REQUEST: 'ALL_CODE_FETCH_REQUEST',
    ALL_CODE_FETCH_SUCCESS: 'ALL_CODE_FETCH_SUCCESS',
    ALL_CODE_FETCH_FAIL: 'ALL_CODE_FETCH_FAIL',
    
    // use cases
    USECASE_FETCH_REQUEST: 'USECASE_FETCH_REQUEST',
    USECASE_FETCH_SUCCESS: 'USECASE_FETCH_SUCCESS',
    USECASE_FETCH_FAIL: 'USECASE_FETCH_FAIL',

    // Users
    USERS_FETCH_REQUEST: 'USERS_FETCH_REQUEST',
    USERS_FETCH_SUCCESS: 'USERS_FETCH_SUCCESS',
    USERS_FETCH_FAIL: 'USERS_FETCH_FAIL',

    USER_FETCH_REQUEST: 'USER_FETCH_REQUEST',
    USER_FETCH_SUCCESS: 'USER_FETCH_SUCCESS',
    USER_FETCH_FAIL: 'USER_FETCH_FAIL',

    USERS_ACTIVE_FETCH_REQUEST: 'USERS_ACTIVE_FETCH_REQUEST',
    USERS_ACTIVE_FETCH_SUCCESS: 'USERS_ACTIVE_FETCH_SUCCESS',
    USERS_ACTIVE_FETCH_FAIL: 'USERS_ACTIVE_FETCH_FAIL',

    USERS_ADD_REQUEST: 'USERS_ADD_REQUEST',
    USERS_ADD_SUCCESS: 'USERS_ADD_SUCCESS',
    USERS_ADD_FAIL: 'USERS_ADD_FAIL',

    USERS_UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    USERS_UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    USERS_UPDATE_FAIL: 'USERS_UPDATE_FAIL',

    USERS_CHANGE_ROLE_REQUEST: 'USERS_CHANGE_ROLE_REQUEST',
    USERS_CHANGE_ROLE_SUCCESS: 'USERS_CHANGE_ROLE_SUCCESS',
    USERS_CHANGE_ROLE_FAIL: 'USERS_CHANGE_ROLE_FAIL',

    USERS_FETCH_SINGLE_REQUEST: 'USERS_FETCH_SINGLE_REQUEST',
    USERS_FETCH_SINGLE_SUCCESS: 'USERS_FETCH_SINGLE_SUCCESS',
    USERS_FETCH_SINGLE_FAIL: 'USERS_FETCH_SINGLE_FAIL',

    USERS_ACTIVATE_REQUEST: 'USERS_ACTIVATE_REQUEST',
    USERS_ACTIVATE_SUCCESS: 'USERS_ACTIVATE_SUCCESS',
    USERS_ACTIVATE_FAIL: 'USERS_ACTIVATE_FAIL',

    USERS_DEACTIVATE_REQUEST: 'USERS_DEACTIVATE_REQUEST',
    USERS_DEACTIVATE_SUCCESS: 'USERS_DEACTIVATE_SUCCESS',
    USERS_DEACTIVATE_FAIL: 'USERS_DEACTIVATE_FAIL',

    // DOCUMENT_INDEX
    DOCUMENT_INDEX_FETCH_REQUEST: 'DOCUMENT_INDEX_FETCH_REQUEST',
    DOCUMENT_INDEX_FETCH_SUCCESS: 'DOCUMENT_INDEX_FETCH_SUCCESS',
    DOCUMENT_INDEX_FETCH_FAIL: 'DOCUMENT_INDEX_FETCH_FAIL',

    DOCUMENT_INDEX_FETCH_SINGLE_REQUEST: 'DOCUMENT_INDEX_FETCH_SINGLE_REQUEST',
    DOCUMENT_INDEX_FETCH_SINGLE_SUCCESS: 'DOCUMENT_INDEX_FETCH_SINGLE_SUCCESS',
    DOCUMENT_INDEX_FETCH_SINGLE_FAIL: 'DOCUMENT_INDEX_FETCH_SINGLE_FAIL',
    
    DOCUMENT_INDEX_FETCH_FAVORITE_REQUEST: 'DOCUMENT_INDEX_FETCH_FAVORITE_REQUEST',
    DOCUMENT_INDEX_FETCH_FAVORITE_SUCCESS: 'DOCUMENT_INDEX_FETCH_FAVORITE_SUCCESS',
    DOCUMENT_INDEX_FETCH_FAVORITE_FAIL: 'DOCUMENT_INDEX_FETCH_FAVORITE_FAIL',

    DOCUMENT_INDEX_FETCH_ARCHIVED_REQUEST: 'DOCUMENT_INDEX_FETCH_ARCHIVED_REQUEST',
    DOCUMENT_INDEX_FETCH_ARCHIVED_SUCCESS: 'DOCUMENT_INDEX_FETCH_ARCHIVED_SUCCESS',
    DOCUMENT_INDEX_FETCH_ARCHIVED_FAIL: 'DOCUMENT_INDEX_FETCH_ARCHIVED_FAIL',

    DOCUMENT_INDEX_CREATE_REQUEST: 'DOCUMENT_INDEX_CREATE_REQUEST',
    DOCUMENT_INDEX_CREATE_SUCCESS: 'DOCUMENT_INDEX_CREATE_SUCCESS',
    DOCUMENT_INDEX_CREATE_FAIL: 'DOCUMENT_INDEX_CREATE_FAIL',

    DOCUMENT_INDEX_UPDATE_REQUEST: 'DOCUMENT_INDEX_UPDATE_REQUEST',
    DOCUMENT_INDEX_UPDATE_SUCCESS: 'DOCUMENT_INDEX_UPDATE_SUCCESS',
    DOCUMENT_INDEX_UPDATE_FAIL: 'DOCUMENT_INDEX_UPDATE_FAIL',

    DOCUMENT_INDEX_DELETE_REQUEST: 'DOCUMENT_INDEX_DELETE_REQUEST',
    DOCUMENT_INDEX_DELETE_SUCCESS: 'DOCUMENT_INDEX_DELETE_SUCCESS',
    DOCUMENT_INDEX_DELETE_FAIL: 'DOCUMENT_INDEX_DELETE_FAIL',

    DOCUMENT_INDEX_USERS_FETCH_REQUEST: 'DOCUMENT_INDEX_USERS_FETCH_REQUEST',
    DOCUMENT_INDEX_USERS_FETCH_SUCCESS: 'DOCUMENT_INDEX_USERS_FETCH_SUCCESS',
    DOCUMENT_INDEX_USERS_FETCH_FAIL: 'DOCUMENT_INDEX_USERS_FETCH_FAIL',

    DOCUMENT_INDEX_USER_ADD_REQUEST: 'DOCUMENT_INDEX_USER_ADD_REQUEST',
    DOCUMENT_INDEX_USER_ADD_SUCCESS: 'DOCUMENT_INDEX_USER_ADD_SUCCESS',
    DOCUMENT_INDEX_USER_ADD_FAIL: 'DOCUMENT_INDEX_USER_ADD_FAIL',

    DOCUMENT_INDEX_USER_REMOVE_REQUEST: 'DOCUMENT_INDEX_USER_REMOVE_REQUEST',
    DOCUMENT_INDEX_USER_REMOVE_SUCCESS: 'DOCUMENT_INDEX_USER_REMOVE_SUCCESS',
    DOCUMENT_INDEX_USER_REMOVE_FAIL: 'DOCUMENT_INDEX_USER_REMOVE_FAIL',

    DOCUMENT_INDEX_USER_ACCESS_RIGHTS_CHANGE_REQUEST: 'DOCUMENT_INDEX_USER_ACCESS_RIGHTS_CHANGE_REQUEST',
    DOCUMENT_INDEX_USER_ACCESS_RIGHTS_CHANGE_SUCCESS: 'DOCUMENT_INDEX_USER_ACCESS_RIGHTS_CHANGE_SUCCESS',
    DOCUMENT_INDEX_USER_ACCESS_RIGHTS_CHANGE_FAIL: 'DOCUMENT_INDEX_USER_ACCESS_RIGHTS_CHANGE_FAIL',

    DOCUMENT_INDEX_FAVORITE_ADD_REQUEST: 'DOCUMENT_INDEX_FAVORITE_ADD_REQUEST',
    DOCUMENT_INDEX_FAVORITE_ADD_SUCCESS: 'DOCUMENT_INDEX_FAVORITE_ADD_SUCCESS',
    DOCUMENT_INDEX_FAVORITE_ADD_FAIL: 'DOCUMENT_INDEX_FAVORITE_ADD_FAIL',

    DOCUMENT_INDEX_FAVORITE_REMOVE_REQUEST: 'DOCUMENT_INDEX_FAVORITE_REMOVE_REQUEST',
    DOCUMENT_INDEX_FAVORITE_REMOVE_SUCCESS: 'DOCUMENT_INDEX_FAVORITE_REMOVE_SUCCESS',
    DOCUMENT_INDEX_FAVORITE_REMOVE_FAIL: 'DOCUMENT_INDEX_FAVORITE_REMOVE_FAIL',

    DOCUMENT_INDEX_ARCHIVE_REQUEST: 'DOCUMENT_INDEX_ARCHIVE_REQUEST',
    DOCUMENT_INDEX_ARCHIVE_SUCCESS: 'DOCUMENT_INDEX_ARCHIVE_SUCCESS',
    DOCUMENT_INDEX_ARCHIVE_FAIL: 'DOCUMENT_INDEX_ARCHIVE_FAIL',

    DOCUMENT_INDEX_UNARCHIVE_REQUEST: 'DOCUMENT_INDEX_UNARCHIVE_REQUEST',
    DOCUMENT_INDEX_UNARCHIVE_SUCCESS: 'DOCUMENT_INDEX_UNARCHIVE_SUCCESS',
    DOCUMENT_INDEX_UNARCHIVE_FAIL: 'DOCUMENT_INDEX_UNARCHIVE_FAIL',

    // DOCUMENT
    DOCUMENT_FETCH_REQUEST: 'DOCUMENT_FETCH_REQUEST',
    DOCUMENT_FETCH_SUCCESS: 'DOCUMENT_FETCH_SUCCESS',
    DOCUMENT_FETCH_FAIL: 'DOCUMENT_FETCH_FAIL',

    DOCUMENT_CREATE_REQUEST: 'DOCUMENT_CREATE_REQUEST',
    DOCUMENT_CREATE_SUCCESS: 'DOCUMENT_CREATE_SUCCESS',
    DOCUMENT_CREATE_FAIL: 'DOCUMENT_CREATE_FAIL',

    DOCUMENT_DELETE_REQUEST: 'DOCUMENT_DELETE_REQUEST',
    DOCUMENT_DELETE_SUCCESS: 'DOCUMENT_DELETE_SUCCESS',
    DOCUMENT_DELETE_FAIL: 'DOCUMENT_DELETE_FAIL',

    DOCUMENT_UPDATE_REQUEST: 'DOCUMENT_UPDATE_REQUEST',
    DOCUMENT_UPDATE_SUCCESS: 'DOCUMENT_UPDATE_SUCCESS',
    DOCUMENT_UPDATE_FAIL: 'DOCUMENT_UPDATE_FAIL',

    DOCUMENT_SEARCH_REQUEST: 'DOCUMENT_SEARCH_REQUEST',
    DOCUMENT_SEARCH_SUCCESS: 'DOCUMENT_SEARCH_SUCCESS',
    DOCUMENT_SEARCH_FAIL: 'DOCUMENT_SEARCH_FAIL',

    DOCUMENT_CHAT_REQUEST: 'DOCUMENT_CHAT_REQUEST',
    DOCUMENT_CHAT_SUCCESS: 'DOCUMENT_CHAT_SUCCESS',
    DOCUMENT_CHAT_FAIL: 'DOCUMENT_CHAT_FAIL',

    DOCUMENT_FETCH_SINGLE_REQUEST: 'DOCUMENT_FETCH_SINGLE_REQUEST',
    DOCUMENT_FETCH_SINGLE_SUCCESS: 'DOCUMENT_FETCH_SINGLE_SUCCESS',
    DOCUMENT_FETCH_SINGLE_FAIL: 'DOCUMENT_FETCH_SINGLE_FAIL',

    // code list
    CODELIST_FETCH_REQUEST: 'CODELIST_FETCH_REQUEST',
    CODELIST_FETCH_SUCCESS: 'CODELIST_FETCH_SUCCESS',
    CODELIST_FETCH_FAIL: 'CODELIST_FETCH_FAIL',

    SHOW_HISTORY: 'SHOW_HISTORY',

    // query history
    QUERY_HISTORY_FETCH_REQUEST: 'QUERY_HISTORY_FETCH_REQUEST',
    QUERY_HISTORY_FETCH_SUCCESS: 'QUERY_HISTORY_FETCH_SUCCESS',
    QUERY_HISTORY_FETCH_FAIL: 'QUERY_HISTORY_FETCH_FAIL',


};
