import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton/IconButton";
import { Menu, MenuItem, useTheme } from "@mui/material";
import Paper from '@mui/material/Paper';
import StarIcon from '@mui/icons-material/Star';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addDocumentIndexFavorite, removeDocumentIndexFavorite, archiveDocumentIndex, unarchiveDocumentIndex } from "../../redux/actions/documentindex";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../constants";
import { USER_ROLE_ADMINISTRATOR, USER_ROLE_READER } from "../../utils/ListItem";


export const DocumentIndexDetail = ({
    documentIndex,
    handleEdit,
    handleDelete,
    userUseCases,
    handleIndexShare,
    user,
    height = 'auto',
    width = '90%',
    addDocumentIndexFavorite,
    removeDocumentIndexFavorite,
    archiveDocumentIndex,
    unarchiveDocumentIndex,
    numberIndex,
    enableFavorite = true,
    enableArchive = true,
}) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleFavorite = () => {
        if (documentIndex.favorite) {
            removeDocumentIndexFavorite(documentIndex.id)
        } else {
            addDocumentIndexFavorite(documentIndex.id);
        }
    };
    const handleArchive = () => {
        if (documentIndex.archived) {
            unarchiveDocumentIndex(documentIndex.id).then(() => {
                handleClose();
            });
        } else {
            archiveDocumentIndex(documentIndex.id).then(() => {
                handleClose();
            });
        }
    }

    const description = documentIndex.description.length > 80 ? documentIndex.description.substring(0, 77) + '...' : documentIndex.description;
    
    return (
        <Paper
            key={documentIndex.id}
            elevation={0}
            sx={{
                padding: 1,
                width: { width },
                height: { height },
                backgroundColor: (theme) => documentIndex.color,
                //boxShadow: `8px 8px 20px 3px ${theme.palette.shadow}`
            }}
        >

            <Grid container height="100%" width="100%">
                <Grid item xs={10}
                    onClick={() => {
                        navigate(`/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}/${encodeURIComponent(documentIndex.index_name)}`);
                    }}
                    sx={{
                        padding: 1,
                        minHeight: '120px',
                        cursor: 'pointer',
                    }}
                >
                    <Typography textAlign="left" variant="h6">
                        {`${documentIndex.index_name}`}
                    </Typography>
                    <Typography textAlign="left" variant="body1">
                        {`${description}`}
                    </Typography>
                </Grid>
                <Grid item xs={2} alignContent="center" sx={{ padding: 1, alignContent: "start" }}>
                    <IconButton aria-label="edit" size="small" onClick={() => {
                    }}>
                        <MoreVertIcon className={`document-index-menu-${numberIndex}`}  onClick={handleClick} />

                    </IconButton>
                </Grid>
                <Grid item xs={9} sx={{
                    padding: 1,
                    alignContent: "center",
                }}>
                    <Typography textAlign="left" variant="body2">
                        {i18n.t("documents", { count: documentIndex.document_count })}
                    </Typography>
                </Grid>
                <Grid item xs={3} alignContent="center" sx={{ padding: 1 }}>
                    <IconButton aria-label="edit" size="small"
                        className={`document-index-favorite-${numberIndex}`}
                        disabled={!enableFavorite}
                        onClick={() => {
                            if (enableFavorite) {
                                handleFavorite();
                            }
                        }}>
                        <StarIcon
                            fontSize="large"
                            sx={{
                                color: theme => documentIndex.favorite ?
                                    theme.palette.background.yellow :
                                    null,
                            }}
                        />
                    </IconButton>
                </Grid>

            </Grid>
            <Menu open={open} anchorEl={anchorEl} onClick={handleClose} onClose={handleClose}>
                <MenuItem disabled={!handleEdit || user?.role === USER_ROLE_READER} onClick={() => handleEdit && handleEdit(documentIndex)}>{t("EDIT")}</MenuItem>
                <MenuItem disabled={!handleIndexShare || user?.role === USER_ROLE_READER} onClick={() => handleIndexShare && handleIndexShare(documentIndex)}>{t("SHARE")}</MenuItem>
                <MenuItem disabled={!enableArchive || user?.role === USER_ROLE_READER} onClick={() => handleArchive(documentIndex)}>{documentIndex.archived ? t("UNARCHIVE") : t("ARCHIVE")}</MenuItem>
                <MenuItem disabled={user?.role === USER_ROLE_READER} onClick={() => handleDelete(documentIndex.id)}>{t("DELETE")}</MenuItem>
            </Menu>
        </Paper>
    )

}

const mapStateToProps = (store) => ({
    user: store.authData.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addDocumentIndexFavorite,
    removeDocumentIndexFavorite,
    archiveDocumentIndex,
    unarchiveDocumentIndex,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentIndexDetail);