import types from '../actionTypes';
import userService from '../../services/users.service';

export const fetchUsers = (query) => (dispatch, getState) => {
    if (getState().userData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.USERS_FETCH_REQUEST,
    });

    return userService.getUsers(query)
        .then((users) => {
            dispatch({
                type: types.USERS_FETCH_SUCCESS,
                payload: { users },
            });

            return users;
        })
        .catch((error) => {
            dispatch({
                type: types.USERS_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
}

export const fetchActiveUsers = (query = "") => (dispatch, getState) => {
    if (getState().userData.isFetchingActiveList) {
        return Promise.reject();
    }

    dispatch({
        type: types.USERS_ACTIVE_FETCH_REQUEST,
    });

    return userService.getActiveUsers(query)
        .then((users) => {
            dispatch({
                type: types.USERS_ACTIVE_FETCH_SUCCESS,
                payload: { users },
            });

            return users;
        })
        .catch((error) => {
            dispatch({
                type: types.USERS_ACTIVE_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
}

export const addUser = (user) => (dispatch, getState) => {
    if (getState().userData.isAdding) {
        return Promise.reject();
    }

    dispatch({
        type: types.USERS_ADD_REQUEST,
    });

    return userService.addUser(user)
        .then((user) => {
            dispatch({
                type: types.USERS_ADD_SUCCESS,
                payload: { user },
            });

            return user;
        })
        .catch((error) => {
            dispatch({
                type: types.USERS_ADD_FAIL,
                payload: { error },
            });

            throw error;
        });
}

export const updateUser = (user) => (dispatch, getState) => {
    if (getState().userData.isUpdatingUser) {
        return Promise.reject();
    }

    dispatch({
        type: types.USERS_UPDATE_REQUEST,
    });

    return userService.updateUser(user.id, user)
        .then((user) => {
            dispatch({
                type: types.USERS_UPDATE_SUCCESS,
                payload: { user },
            });
            return user;
        })
        .catch((err) => {
            dispatch({
                type: types.USERS_UPDATE_FAIL,
                payload: { err },
            });
            return false;
        });
}

export const changeRole = (userId, role) => (dispatch, getState) => {
    if (getState().userData.isChangingRole) {
        return Promise.reject();
    }

    dispatch({
        type: types.USERS_CHANGE_ROLE_REQUEST,
    });

    return userService.changeRole(userId, role)
        .then((user) => {
            dispatch({
                type: types.USERS_CHANGE_ROLE_SUCCESS,
                payload: { user },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.USERS_CHANGE_ROLE_FAIL,
                payload: { error },
            });

            throw error;
        });
}

export const fetchUser = (userId) => (dispatch, getState) => {
    if (getState().userData.isFetching) {
        return Promise.reject();
    }

    dispatch({
        type: types.USER_FETCH_REQUEST,
    });

    return userService.getUser(userId)
        .then((user) => {
            dispatch({
                type: types.USER_FETCH_SUCCESS,
                payload: { user },
            });

            return user;
        })
        .catch((error) => {
            dispatch({
                type: types.USER_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
}

export const activateUser = (userId) => (dispatch, getState) => {
    if (getState().userData.isUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.USERS_ACTIVATE_REQUEST,
    });

    return userService.activateUser(userId)
        .then((user) => {
            dispatch({
                type: types.USERS_ACTIVATE_SUCCESS,
                payload: { user },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.USERS_ACTIVATE_FAIL,
                payload: { error },
            });

            throw error;
        });
}

export const deactivateUser = (userId) => (dispatch, getState) => {
    if (getState().userData.isUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.USERS_DEACTIVATE_REQUEST,
    });

    return userService.deactivateUser(userId)
        .then((user) => {
            dispatch({
                type: types.USERS_DEACTIVATE_SUCCESS,
                payload: { user },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.USERS_DEACTIVATE_FAIL,
                payload: { error },
            });

            throw error;
        });
}
