import React, { useEffect, useMemo, useRef, useState } from "react";
import Markdown from 'react-markdown';
import { Card, CardContent, Divider, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { HtmlTooltip, SourceText } from ".";
import { useTheme } from "@emotion/react";

const ChatAnswer = ({ answer, i, isCursorVisible, isLoadingSearch, chatHistory }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const resources = useMemo(() => {
        console.log("answer", answer);
        return answer.resources?.reduce((acc, res) => {
            if (acc[res.name] === undefined)
                acc[res.name] = [];
            acc[res.name].push(res);
            return acc;
        }, {});
    }, [answer]);

    return <>
        <Grid item xs={12} sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'
        }}>
            <Card elevation={0} sx={{ maxWidth: '50%', backgroundColor: 'secondary.main', textAlign: "right" }}>
                <CardContent>
                    <Typography variant="body1">
                        {answer.query}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Card elevation={0} sx={{ maxWidth: '50%', minHeight: 90, backgroundColor: 'background.grey', textAlign: "left" }}>
                <CardContent sx={{ maxWidth: "100%" }}>
                    <Typography variant="body1" gutterBottom>
                        {/* https://medium.com/@MatDrinksTea/rendering-markdown-and-latex-in-react-dec355e74119 */}
                        <Markdown>{answer.answer + (i === chatHistory.length - 1 && isCursorVisible && isLoadingSearch ? "|" : " ")}</Markdown>
                    </Typography>

                    {resources && Object.entries(resources).length > 0 && <Grid item xs={6} sx={{ maxWidth: "100% !important" }}>
                        <Grid sx={{ maxWidth: "100%" }} container direction="row-reverse" justifyContent="flex-start" spacing={1}>
                            {Object.entries(resources).map((entry, i) => {
                                const [name, res] = entry;

                                return (
                                    <Grid item xs="auto">
                                        <HtmlTooltip
                                            title={<React.Fragment>
                                                <Typography variant="h6" sx={{paddingLeft: 1, paddingTop: 1}}>
                                                    {name}
                                                </Typography>
                                                {res.map((singleResource, singleResourceIndex) => <>
                                                {singleResourceIndex > 0 && <Divider sx={{marginLeft: 1, marginRight: 1}}/>}
                                                <SourceText color="inherit">{singleResource.content}</SourceText>
                                                </>)}
                                            </React.Fragment>}
                                        >
                                            <Typography variant="body1" gutterBottom textAlign="center" sx={{
                                                border: `1px solid ${theme.palette.background.grey600}`,
                                                color: theme.palette.background.grey600,
                                                borderRadius: '50%',
                                                lineHeight: "28px", /* set to same size as width, height */
                                                width: "28px",
                                                height: "28px",
                                            }}>
                                                {Object.entries(resources).length - i}
                                            </Typography>
                                        </HtmlTooltip>
                                    </Grid>
                                );
                            })}
                            <Grid item xs="auto">
                                <Typography variant="body1" gutterBottom color={theme.palette.background.grey600}>
                                    {t("RESOURCES")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>}
                </CardContent>
            </Card>
        </Grid>

    </>;

};
export const ChatAnswers = ({ chatHistory, isLoadingSearch, query }) => {
    const scrollRef = useRef(null);
    const [isCursorVisible, setIsCursorVisible] = useState(true);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behaviour: "smooth" });
        }
    }, [chatHistory, isLoadingSearch, query]);


    useEffect(() => {
        // Toggle cursor visibility every 500 milliseconds
        const intervalId = setInterval(() => {
            setIsCursorVisible(prev => !prev);
        }, 500);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    return (
        <Grid container spacing={1} item xs={12} sx={{
            maxHeight: '70vh', overflow: 'auto', marginTop: 4, marginRight: 4
        }}>

            {chatHistory.map((answer, i) => <ChatAnswer
                answer={answer}
                i={i}
                isCursorVisible={isLoadingSearch && isCursorVisible}
                isLoadingSearch={isLoadingSearch}
                chatHistory={chatHistory} />)}
            {/*isLoadingSearch &&
                <>
                    <Grid item xs={12} sx={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'
                    }}>
                        <Card elevation={0} sx={{ maxWidth: '50%', backgroundColor: 'secondary.main', textAlign: "right" }}>
                            <CardContent>
                                <Typography variant="body1" gutterBottom>
                                    {query}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card elevation={0} sx={{ maxWidth: '50%', backgroundColor: 'background.grey', textAlign: "left" }}>
                            <CardContent>
                                <Loading />

                            </CardContent>
                        </Card>
                    </Grid>
                </>*/}
            <Grid item xs={12} ref={scrollRef}></Grid>
        </Grid>

    );
};
