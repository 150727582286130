import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
    deleteDocumentIndex,
    fetchFavoriteDocumentIndexes,
    updateDocumentIndex
} from '../../redux/actions/documentindex';
import { Box, Button, Divider, Grid, Grow, Link as LinkBase, Typography } from "@mui/material";
import DocumentIndexDetail from "../../components/DocumentIndexDetail";
import ScheduleIcon from '@mui/icons-material/Schedule';
import { showHistory } from "../../redux/actions/global";
import { useTranslation } from "react-i18next";
import IndexUpdate from "../../components/IndexUpdate";
import IndexShare from "../../components/IndexShare";
import { PATHS } from "../../constants";
import { Link } from "react-router-dom";
import { USER_ROLE_READER } from "../../utils/ListItem";


const Home = (props) => {
    const {
        user,
        documentIndexes,
        updateDocumentIndex,
        deleteDocumentIndex,
        history,
        showHistory,
        fetchFavoriteDocumentIndexes
    } = props;

    const [openModal, setOpenModal] = React.useState(false);
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [indexConfigModel, setIndexConfigModel] = useState({
        open: false,
        index: {},
        isNew: true
    });

    const [indexShareModel, setIndexShareModel] = useState({
        open: false,
        index: {},
    });
    const [areDocumentIndexesFetched, setAreDocumentIndexesFetched] = useState(false);

    const handleIndexUpdate = (updatedIndex) => {
        let index = { ...updatedIndex }
        setIndexConfigModel({ isNew: false, index: index, open: true, });

    };
    const closeIndexShare = () => {
        setIndexShareModel({ index: {}, open: false });
    }

    const closeIndexDetail = () => {
        setIndexConfigModel({ isNew: false, index: {}, open: false });
    }
    const handleIndexShare = (updatedIndex) => {
        let index = { ...updatedIndex }
        setIndexShareModel({ index: index, open: true });

    };


    useEffect(() => {
        if (!areDocumentIndexesFetched) {
            fetchFavoriteDocumentIndexes().then(() => {
                setAreDocumentIndexesFetched(true);
            })
        }
    }, [areDocumentIndexesFetched, fetchFavoriteDocumentIndexes]);


    const renderDocumentIndexes = () => {
        return !!documentIndexes && documentIndexes.map((item, numberIndex) => (
            <Grow
                in={true}
                style={{ transformOrigin: '0 0 0' }}
                timeout={Math.min(numberIndex * 200, 1000)}
            >
                <Grid item xs={12} sm={8} md={6} lg={4} xl={3} key={item.id} sx={{ padding: 2 }}>
                    <DocumentIndexDetail handleEdit={handleIndexUpdate} handleIndexShare={handleIndexShare}
                        handleDelete={deleteDocumentIndex} documentIndex={item} />
                </Grid>
            </Grow>

        ));
    }
    const saveIndex = async (index) => {
        setSaving(true);
        index = { ...index }
        if (index.color && index.color.hex) {
            index.color = index.color.hex;
        }

        try {
            await updateDocumentIndex(index);
            setAreDocumentIndexesFetched(false);
            setSaving(false);
            closeIndexDetail();
        } catch (error) {
            setSaving(false);
        }

    };

    return (
        <Grid container>
            {user.role !== USER_ROLE_READER && <IndexUpdate
                indexConfigModel={indexConfigModel}
                saveIndex={saveIndex}
                handleClose={closeIndexDetail}
                saving={saving}
            />}
            {user.role !== USER_ROLE_READER && <IndexShare
                indexConfigModel={indexShareModel}
                handleClose={closeIndexShare}
                saving={saving}
            />}
            <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography align="left" variant='h4'
                    style={{ paddingRight: 20 }}>{t('HOME')}</Typography>
            </Grid>

            <Grid item xs={4}>
                <Box display="flex" justifyContent="flex-end">
                    <Button id="query-history" variant="contained" endIcon={<ScheduleIcon />}
                        sx={{
                            width: '150px', margin: 1, color: 'primary.contrastText', bgColor: 'background.grey'
                        }}
                        onClick={() => showHistory(!history)}>{t('HISTORY')}</Button>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />
            </Grid>
            <Grid item xs={12} p={3} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography id="favorite-binders" align="left" variant='h6'
                    style={{ paddingRight: 20 }}>{t('YOUR_FAVORITE_BINDERS')}</Typography>
                <LinkBase sx={{ color: 'grey', textDecoration: 'underline', fontSize: 10 }} component={Link} to={`/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}`}>
                    {t('VIEW_ALL')}
                </LinkBase>
            </Grid>
            <Grid container sx={{ marginTop: "10px", overflow: 'auto' }}>
                {renderDocumentIndexes()}
            </Grid>

        </Grid >
    );
}

Home.propTypes = {
    user: PropTypes.object,
};

Home.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    documentIndexes: store.documentIndexData.favoriteDocumentIndexes,
    history: store.globalData.history
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateDocumentIndex,
    deleteDocumentIndex,
    fetchFavoriteDocumentIndexes,
    showHistory,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Home);
