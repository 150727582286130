import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { Alert, Card, Collapse, Snackbar } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from "react-error-boundary";
import logo from '../../img/logo.png'
import { logout } from "../../redux/actions/auth";
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FolderIcon from '@mui/icons-material/Folder';
import { fetchDocumentIndexes } from '../../redux/actions/documentindex';
import { fetchQueryHistory } from '../../redux/actions/queryhistory';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import LensIcon from '@mui/icons-material/Lens';
import { PATHS } from "../../constants";
import Index from "../../components/Loading";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton/IconButton";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { showHistory } from "../../redux/actions/global";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Loading from '../../components/Loading';
import { ORIGIN_HISTORY_ID } from '../Documents';

const GreenCircle = styled(LensIcon)(({ theme }) => ({
    color: theme.palette.secondary.main,
}));

const BlueCircle = styled(LensIcon)(({ theme }) => ({
    color: theme.palette.primary.third,
}));

const QueryHistory = ({
    user,
    history,
    queryHistory,
    showHistory,
    fetchQueryHistory,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (history) {
            setLoading(true);
            fetchQueryHistory().then(() => {
                setLoading(false);
            });
        }
    }, [history]);

    const redirectToChat = (historyItem) => {
        showHistory(false);
        console.log("historyItem", historyItem);
        navigate(`/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}/${encodeURIComponent(historyItem.document_index_name)}?${ORIGIN_HISTORY_ID}=${historyItem.id}`);
    }


    return (
        <Grid container rowSpacing={1} width="100%" sx={{
            padding: 3,
        }}>
            <Grid item xs={3}>
                <Typography textAlign="left" variant="h6">
                    {t('HISTORY')}
                </Typography>
            </Grid>
            <Grid item xs={9} alignContent="center">
                <IconButton color="secondary" aria-label="edit" size="small" onClick={() => {
                    showHistory(false);
                }}>
                    <ChevronRightIcon />

                </IconButton>
            </Grid>
            {loading &&
                <Grid item xs={12}>
                    <Typography textAlign="left" variant="body2">
                        <Loading />
                    </Typography>
                </Grid>
            }
            {!loading && queryHistory.map((item, index) => (
                <>
                    <Grid item xs={8}>
                        <Typography textAlign="left" variant="body1">
                            {item.document_index_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} alignContent="center">
                        <Typography textAlign="left" variant="body2">
                            {item.query_date}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Card 
                            elevation={0} 
                            sx={{padding: 2, cursor: 'pointer'}}
                            onClick={() => redirectToChat(item)}>
                            <Grid container direction={"row"} rowSpacing={1} columnSpacing={2}>
                                <Grid item xs={2}>
                                    {item.query_type?.startsWith('ask') ? (
                                        <BlueCircle />
                                    ) : (
                                        <GreenCircle />
                                    )}
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography textAlign="left" variant="body1">
                                        {item.query.length < 90 ? item.query : item.query.substring(0, 87) + '...'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />
                    </Grid>
                </>
            ))}

        </Grid >
    );
};


QueryHistory.propTypes = {
    user: PropTypes.object,
};

QueryHistory.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    history: store.globalData.history,
    queryHistory: store.queryHistoryData.queryHistory,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    logout,
    fetchDocumentIndexes,
    showHistory,
    fetchQueryHistory,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(QueryHistory);
