import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationEN from './translation/locales/en/translation.json';
import translationCS from './translation/locales/cs/translation.json';

import {
    cs, enGB
} from 'date-fns/esm/locale';


const resources = {
    en: {
        translation: translationEN,
    },
    cs: {
        translation: translationCS,
    },

};

const availableLanguages = ['en', 'cs'];

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        lng: 'en', // set language
        fallbackLng: 'en',
        whitelist: availableLanguages,

        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });


export function getLocale(language) {
    const formats = {
        cs,
        en: enGB,
    };
    return formats[language] || enGB;
}


export function getLocaleDateString(language) {
    const formats = {
        cs: 'd.M.yyyy',
        en: 'MM/dd/yyyy',
    };
    return formats[language] || 'dd/MM/yyyy';
}


export function getLocaleDateTimeString(language) {
    const formats = {
        cs: 'd.M.yyyy HH:mm:ss',
        en: 'MM/dd/yyyy hh:mm:ss',
    };
    return formats[language] || 'dd/MM/yyyy HH:mm:ss';
}

export function getAMPM(language) {
    const formats = {
        cs: false,
        en: true,
    };
    return formats[language] || false;
}

export default i18n;
