import {combineReducers} from 'redux';

import authData from './auth';
import documentIndexData from './documentindex';
import documentData from './document';
import codelistData from './codelist';
import globalData from './global';
import userData from './user';
import queryHistoryData from './queryhistory';

export default () => combineReducers({
    authData,
    documentIndexData,
    documentData,
    codelistData,
    globalData,
    userData,
    queryHistoryData,
});
