import httpService from './http.service';

const getUsers = (query) => httpService
    .get(`/user${query ? "?query=" + encodeURIComponent(query) : ""}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getActiveUsers = (query) => httpService
    .get(`/user/active${query ? "?query=" + encodeURIComponent(query) : ""}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const addUser = (user) => httpService
    .post(`/user`, user)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));

const updateUser = (userId, user) => httpService
    .put(`/user/${userId}`, user)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));

const changeRole = (userId, role) => httpService
    .post(`/user/change_role`, { userId: userId, role: role })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));

const getUser = (userId) => httpService
    .get(`/user/${userId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const activateUser = (userId) => httpService
    .post(`/user/${userId}/active`, { "active": true })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));

const deactivateUser = (userId) => httpService
    .post(`/user/${userId}/active`, { "active": false })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));

export default {
    getUsers,
    getActiveUsers,
    addUser,
    updateUser,
    changeRole,
    getUser,
    activateUser,
    deactivateUser,
};
