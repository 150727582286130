import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import { Autocomplete, CircularProgress, Divider, IconButton, OutlinedInput, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/system";
import { fetchActiveUsers } from "../../redux/actions/user";
import {
    fetchDocumentIndexUsers,
    addUserToDocumentIndex,
    removeUserFromDocumentIndex,
    changeUserAccessRights,
} from "../../redux/actions/documentindex";
import SearchIcon from '@mui/icons-material/Search';
import Grid from "@mui/material/Grid";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { CirclePicker } from "react-color";
import DeleteIcon from '@mui/icons-material/Delete';

const FormFields = styled('div')(({ theme }) => ({
    height: '90%',
    width: '90%',
    flexDirection: 'row',

    alignItems: 'flex-start',
    paddingTop: '24px',
    marginLeft: 'auto',
    marginRight: 'auto',

}));

const TextContainer = styled('div')(({ theme }) => ({
    paddingTop: 24,

}));

const ToggleButtonSelected = styled(ToggleButton)(({ theme }) => ({
    border: '0px solid',
    borderTopRightRadius: '15px !important',
    borderBottomRightRadius: '15px !important',
    borderTopLeftRadius: '15px !important',
    borderBottomLeftRadius: '15px !important',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.background.grey,
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: '#2e3192',
        borderTopRightRadius: '15px !important',
        borderBottomRightRadius: '15px !important',
        borderTopLeftRadius: '15px !important',
        borderBottomLeftRadius: '15px !important',
    },
    "&.Mui-disabled, &.Mui-disabled:hover": {
        border: '0px solid',
        opacity: 0.7,
    },
}));

const UserCard = (props) => {
    const {
        documentIndex,
        user,
        accessRight,
        removeUserFromDocumentIndex,
        changeUserAccessRights,
    } = props;

    console.log('user', user);

    return (
        <>
            <Grid item xs={1} >
                {user.profile_picture ?
                    <img src={user.profile_picture} alt={user.username} style={{ width: 50, height: 50, borderRadius: '50%' }} />
                    :
                    <AccountCircleIcon sx={{ width: 50, height: 50, color: (theme) => theme.palette.secondary.main }} />
                }
            </Grid>
            <Grid item xs={8} >
                <Grid container>
                    <Grid item xs={12} >
                        <Typography variant='h7'
                            sx={{
                                color: (theme) => theme.palette.primary.contrastText,
                            }}>{user.first_name} {user.last_name}</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant='h7'
                            sx={{
                                color: (theme) => theme.palette.background.grey400,
                            }}>{user.username}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2} >
                <ToggleButtonGroup
                    sx={{
                        border: '4px solid',
                        height: 40,
                        backgroundColor: 'background.grey',
                        borderColor: 'background.grey',
                        borderRadius: 15
                    }}
                    color="secondary"
                    value={accessRight}
                    exclusive
                    disabled={user.id === documentIndex.owner_id}
                    onChange={(event, value) => {
                        if (value) {
                            changeUserAccessRights(documentIndex.id, user.id, value);
                        }
                    }}
                    aria-label="Platform"
                    size="small"
                >
                    <ToggleButtonSelected value="viewer">Viewer</ToggleButtonSelected>
                    <ToggleButtonSelected value="editor">Editor</ToggleButtonSelected>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={0.5} >
            </Grid>
            <Grid item xs={0.5} >
                <IconButton
                    disabled={user.id === documentIndex.owner_id}
                    onClick={() => removeUserFromDocumentIndex(documentIndex.id, user.id)}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </>
    )
}

function IndexShare(props) {
    const {
        handleClose,
        indexConfigModel,
        saving,
        activeUsers,
        fetchActiveUsers,
        isFetchingActiveUsers,
        fetchDocumentIndexUsers,
        assignedUsers,
        isFetchingAssignedUsers,
        addUserToDocumentIndex,
        removeUserFromDocumentIndex,
        changeUserAccessRights,
    } = props;


    const { t } = useTranslation();

    const [updatedIndex, setUpdatedIndex] = useState({});
    const [username, setUsername] = useState(null);

    const [errorStep1, setErrorStep1] = useState('');

    useEffect(() => {
        if (!activeUsers && !isFetchingActiveUsers) {
            fetchActiveUsers();
        }
    }, [indexConfigModel]);

    useEffect(() => {
        if (indexConfigModel?.index?.id && !isFetchingAssignedUsers) {
            fetchDocumentIndexUsers(indexConfigModel.index.id);
        }
    }, [indexConfigModel]);

    useEffect(() => {
        if (indexConfigModel.index) {
            setUpdatedIndex(indexConfigModel.index);

        }
    }, [indexConfigModel]);


    const handleChange = (name) => (event) => {
        if (event && event.target) {
            setUpdatedIndex({ ...updatedIndex, [name]: event.target.value });
        } else {
            setUpdatedIndex({ ...updatedIndex, [name]: event });
        }
    }


    return (

        <Dialog
            maxWidth="md"
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    height: '70%',
                    margin: 1,
                },
            }}
            open={indexConfigModel.open}
            onClose={handleClose}
        >

            <DialogTitle sx={{
                height: '40px',
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 4,
                paddingBottom: 3,
                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                width: '100%',
            }}>
                <>
                    <Typography variant='h7'
                        sx={{ color: (theme) => theme.palette.background.contrastText }}>{t('SHARE_BINDER')}</Typography>
                </>

            </DialogTitle>


            <DialogContent>
                <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />

                <FormFields>
                    <Autocomplete
                        fullWidth
                        getOptionLabel={(option) => option.username}
                        options={activeUsers}
                        loading={isFetchingActiveUsers}
                        value={username}
                        onChange={(event, newValue) => {
                            setUsername(newValue);
                            addUserToDocumentIndex(indexConfigModel.index.id, newValue.id, 'viewer').then(() => {
                                setUsername(null);
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {isFetchingActiveUsers ? <CircularProgress color="inherit" size={20} /> : null}
                                            {/*params.InputProps.endAdornment*/}
                                            <SearchIcon />
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <TextContainer>
                        <Typography variant='h7'
                            sx={{
                                color: (theme) => theme.palette.background.contrastText,
                            }}>{t('SHARE_BINDER_DESCRIPTION')}</Typography>
                    </TextContainer>
                    <Grid container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ paddingTop: 2 }}>
                        {assignedUsers && assignedUsers.map((user) =>
                            <UserCard
                                documentIndex={indexConfigModel.index}
                                user={user.user}
                                accessRight={user.access_right}
                                removeUserFromDocumentIndex={removeUserFromDocumentIndex}
                                changeUserAccessRights={changeUserAccessRights}
                            />
                        )
                        }
                    </Grid>
                </FormFields>
            </DialogContent>

            <DialogActions
                sx={{
                    padding: 0, height: 100, width: '100%', paddingRight: 4,
                }}>

                <Button
                    sx={{
                        width: '150px', margin: 1,
                        backgroundColor: 'primary.third',
                        color: 'primary.selection',
                    }}
                    variant="contained"
                    onClick={handleClose}
                > {t('DONE')}</Button>

            </DialogActions>


        </Dialog>

    );
}

IndexShare.propTypes = {};

const mapStateToProps = (store) => ({
    activeUsers: store.userData.activeUsers,
    isFetchingActiveUsers: store.userData.isFetchingActiveList,
    assignedUsers: store.documentIndexData.documentIndexUsers,
    isFetchingAssignedUsers: store.documentIndexData.isFetchingUserList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchActiveUsers,
    fetchDocumentIndexUsers,
    addUserToDocumentIndex,
    removeUserFromDocumentIndex,
    changeUserAccessRights,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IndexShare);


