import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingAllList: false,
    errors: '',
    queryHistory: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.QUERY_HISTORY_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };
        case types.QUERY_HISTORY_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                queryHistory: action.payload.queryHistory,
            };
        case types.QUERY_HISTORY_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };
        default:
            return state;
    }
};
