import {keycloak} from '../../services/keycloak.service';
import actionTypes from '../actionTypes';


// eslint-disable-next-line import/prefer-default-export
export const logout = () => (dispatch) => {
    localStorage.removeItem('kc_token');
    localStorage.removeItem('kc_idToken');
    localStorage.removeItem('kc_refreshToken');

    dispatch({
        type: actionTypes.LOG_OUT,
    });

    keycloak.logout();
};