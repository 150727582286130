import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
    createDocumentIndex,
    deleteDocumentIndex,
    fetchDocumentIndexes,
    updateDocumentIndex
} from '../../redux/actions/documentindex';
import { MenuLinksContext } from '../Page';
import { Box, Button, Divider, Grid, Grow, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import DocumentIndexDetail from "../../components/DocumentIndexDetail";
import { Search } from "@mui/icons-material";
import Index from "../../components/Loading";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { showHistory } from "../../redux/actions/global";
import { useTranslation } from "react-i18next";
import IndexUpdate from "../../components/IndexUpdate";
import IndexShare from "../../components/IndexShare";
import { USER_ROLE_READER } from "../../utils/ListItem";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const DocumentIndex = (props) => {
    const {
        user,
        documentIndexes,
        fetchDocumentIndexes,
        createDocumentIndex,
        updateDocumentIndex,
        deleteDocumentIndex,
        showHistory,
        history
    } = props;

    const [isLoading, setIsLoading] = useState(true);

    const [areDocumentIndexesFetched, setAreDocumentIndexesFetched] = useState(false);


    const [saving, setSaving] = useState(false);
    const [indexConfigModel, setIndexConfigModel] = useState({
        open: false,
        index: {},
        isNew: true
    });

    const [indexShareModel, setIndexShareModel] = useState({
        open: false,
        index: {},
    });

    const { t } = useTranslation();

    useEffect(() => {
        if (!areDocumentIndexesFetched) {

            fetchDocumentIndexes().finally(() => {
                setIsLoading(false);
                setAreDocumentIndexesFetched(true);
            });
        }
    }, [areDocumentIndexesFetched]);

    const renderDocumentIndexes = () => {
        return !!documentIndexes && documentIndexes.map((item, numberIndex) => (
            <Grow
                in={true}
                style={{ transformOrigin: '0 0 0' }}
                timeout={numberIndex * 100}
            >
                <Grid item xs={12} sm={8} md={6} lg={4} xl={3} key={item.id} sx={{ padding: 2 }}>
                    <DocumentIndexDetail
                        numberIndex={numberIndex}
                        handleIndexShare={handleIndexShare}
                        handleEdit={handleIndexUpdate} handleDelete={deleteDocumentIndex} documentIndex={item} />
                </Grid>
            </Grow>
        ));
    }

    const closeIndexDetail = () => {
        setIndexConfigModel({ isNew: false, index: {}, open: false });
    }

    const closeIndexShare = () => {
        setIndexShareModel({ index: {}, open: false });
    }


    const handleNewIndex = () => {
        setIndexConfigModel({ isNew: true, group: {}, open: true });
    }
    const handleIndexUpdate = (updatedIndex) => {
        let index = { ...updatedIndex }
        setIndexConfigModel({ isNew: false, index: index, open: true, });

    };
    const handleIndexShare = (updatedIndex) => {
        let index = { ...updatedIndex }
        setIndexShareModel({ index: index, open: true });

    };

    const saveIndex = async (index) => {
        setSaving(true);
        index = { ...index }
        if (index.color && index.color.hex) {
            index.color = index.color.hex;
        }

        if (index.description === null || index.description === undefined) {
            index.description = '';
        }

        const action = (indexConfigModel && indexConfigModel.isNew) ? createDocumentIndex : updateDocumentIndex;

        try {
            await action(index);
            setAreDocumentIndexesFetched(false);
            setSaving(false);
            closeIndexDetail();
        } catch (error) {
            setSaving(false);
        }

    };

    return (
        <Grid container>


            {user.role !== USER_ROLE_READER && <IndexUpdate
                indexConfigModel={indexConfigModel}
                saveIndex={saveIndex}
                handleClose={closeIndexDetail}
                saving={saving}
            />}

            {user.role !== USER_ROLE_READER && <IndexShare
                indexConfigModel={indexShareModel}
                handleClose={closeIndexShare}
                saving={saving}
            />}

            <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography align="left" variant='h4'
                    style={{ paddingRight: 20 }}>{t('BINDERS')}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Box display="flex" justifyContent="flex-end">
                    <Button variant="contained" endIcon={<ScheduleIcon />}
                        sx={{
                            width: '150px', margin: 1, color: 'primary.contrastText', bgColor: 'background.grey',
                        }}
                        onClick={() => showHistory(!history)}>{t('HISTORY')}</Button>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />
            </Grid>
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={3} p={1}>
                {/*<TextField
                    id="search-index-input"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    size="small"
                    fullWidth={true}
                    label={t('SEARCH_BINDERS')}
                    type="search"
                    onChange={(event) => {


                    }}
                    variant="outlined" />*/}
            </Grid>
            <Grid item xs={3}>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        disabled={user.role === USER_ROLE_READER}
                        id="add-index-button"
                        sx={{
                            width: '150px', margin: 1
                        }}
                        color="secondary"
                        variant="contained" onClick={() => handleNewIndex()}>{t("ADD_INDEX")}</Button>
                </Box>
            </Grid>

            {isLoading && <Index />}
            <Grid id="all-index-list" container sx={{ marginTop: "10px", overflow: 'auto', maxHeight: 'calc(100vh - 130px)' }}>
                {renderDocumentIndexes()}
            </Grid>
        </Grid>
    );
}

DocumentIndex.propTypes = {
    user: PropTypes.object,
};

DocumentIndex.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    documentIndexes: store.documentIndexData.documentIndexes,
    history: store.globalData.history
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchDocumentIndexes,
    createDocumentIndex,
    updateDocumentIndex,
    deleteDocumentIndex,
    showHistory,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentIndex);