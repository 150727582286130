import React from "react";
import './App.css';
import { styled } from '@mui/system';
import { Navigate, Routes, Route } from 'react-router-dom';
import Layout from './containers/Page';
import DocumentIndexes from "./containers/DocumentIndexes";
import Home from "./containers/Home";
import Documents from "./containers/Documents";
import DocumentDetail from "./containers/DocumentDetail";
import ArchivedIndexes from "./containers/ArchivedIndexes";
import {PATHS} from "./constants";
import Settings from "./containers/Settings";

const RootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
}));

const MainContent = styled('main')(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'auto',
}));

const AppBarSpacer = styled('div')(({ theme }) => ({
  height: 50,
  //...theme.mixins.toolbar,
}));

const AppContent = styled('div')(({ theme }) => ({
  height: 'calc(100% - 70px)',
  //padding: theme.spacing(1.875),
  paddingTop: 60,
  '& > .scrollbar-container': {
    //padding: theme.spacing(1.875),
  },
}));

function App() {
  return (
    <div className="App">
      <RootDiv>
        <Layout>
          <Routes >
            <Route exact path={'/'} element={<Navigate to={`/${process.env.REACT_APP_PATH}`} />} />
            <Route path={`/${process.env.REACT_APP_PATH}`} element={<Home />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}`} element={<DocumentIndexes />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/${PATHS.ARCHIVED_DOCUMENTS}`} element={<ArchivedIndexes />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENTS}`} element={<Documents />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_DETAIL}`} element={<DocumentDetail />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/${PATHS.SETTINGS}`} element={<Settings />} />
          </Routes>
        </Layout>
      </RootDiv>
    </div >
  );
}

export default App;

