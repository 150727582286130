import React from 'react';

import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { radioClasses } from '@mui/material/Radio';
import { colors } from "@mui/material";


export const themeOptionsLight = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
        ].join(','),
        h3: {
            fontSize: 32,
            fontWeight: 500
        },

        h4: {
            fontSize: 30,
            fontWeight: 500
        },

        body1: { fontSize: 14, fontWeight: 500 },
        body2: { fontSize: 12 },

    },
    shape: {
        borderRadius: 15,
    },
    palette: {
        mode: 'light',
        primary: {
            main: 'rgb(239,239,239)',
            selection: '#FFF',
            contrastText: colors.common.black,
            third: '#2e3192',
            light: '#eeefff',

        },
        secondary: {
            main: 'rgb(188,206,97)',
            light: '#e6ee8d',
            light2: '#eef3c8',
            selection: '#000',
            contrastText: colors.common.black,
            third: '#758140',

        },
        background: {
            default: colors.common.white,
            grey100: colors.grey[100],
            grey200: colors.grey[200],
            grey400: colors.grey[400],
            grey600: colors.grey[600],
            grey800: colors.grey[800],
            paper: colors.common.white,
            grey: '#EFEFEF',
            selected: '#51b148',
            yellow: '#f5b800',
        },
        info: {
            main: '#0f6cd1',
            contrastText: colors.common.white,
        },
        shadow: '#d3d3d3',
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: 20,
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '12px',
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 15,
                    "& input": {
                        padding: "0.7rem 1rem",
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    borderRadius: 15,
                    "& div": {
                        padding: "0.7rem 1rem",
                    }
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: colors.common.black,
                    [`&.${radioClasses.checked}`]: {
                        color: colors.common.black,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: colors.common.black,
                    [`&.${radioClasses.checked}`]: {
                        color: colors.common.black,
                    },
                },
            },
        },
    }

});

export const themeOptionsDark = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
        ].join(','),
        h4: {
            fontSize: 41,
            fontWeight: 600
        },
        body1: { fontSize: 14, fontWeight: 500 },
        body2: { fontSize: 12 },

    },
    shape: {
        borderRadius: 20,
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#5b97ce',
            selection: '#000',
            third: '#5b97ce',
            third: '#2e3192',
        },
        secondary: {
            main: 'rgb(188,206,97)',
            selection: '#FFF',
            third: '#758140',
        },
        background: {
            default: colors.common.white,
            grey100: colors.grey[100],
            grey200: colors.grey[200],
            grey400: colors.grey[400],
            grey600: colors.grey[600],
            grey800: colors.grey[800],
            paper: colors.common.white,
            grey: '#EFEFEF',
            selected: '#51b148',
            yellow: '#f5b800',
        },
        info: {
            main: '#0f6cd1',
            contrastText: colors.common.white,
        },
        shadow: '#1e1e1e',
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: 20,
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '12px',
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true
            }
        }
    }
});


export default props => (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={props.mode === 'dark' ? themeOptionsDark : themeOptionsLight}>
            {props.children}</ThemeProvider>
    </StyledEngineProvider>
);
