import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import { CircularProgress, Divider, FormHelperText, IconButton, InputAdornment, InputBase, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { CirclePicker } from "react-color";
import { addUser, updateUser } from '../../redux/actions/user';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSetState } from 'react-use';
import { StyledTextInput } from '../StyledTextInput';
import InfoIcon from '@mui/icons-material/Info';
import { generateRandomString } from '../../utils';
import { USER_ROLE_ADMINISTRATOR, USER_ROLE_CONTRIBUTOR, USER_ROLE_READER } from '../../utils/ListItem';
import DoneIcon from '@mui/icons-material/Done';

const FormFields = styled('div')(({ theme }) => ({
    height: '100%',
    width: '100%',
    flexDirection: 'row',

    alignItems: 'flex-start',
    paddingTop: '50px',

}));

const UserUpdate = ({
    handleClose,
    addUser,
    updateUser,
    userConfigModel,
    isAdding,
    isUpdating,
}) => {
    const { t } = useTranslation();

    const [updatedUser, setUpdatedUser] = useState({});
    const [passwordCopied, setPasswordCopied] = useState(false);

    const [error, setError] = useState({});

    const saving = isAdding || isUpdating;

    useEffect(() => {
        if (userConfigModel.open) {
            let currUser = userConfigModel.user;
            if (userConfigModel.isNew) {
                currUser = { ...currUser, password: generateRandomString(20) };
                setPasswordCopied(false);
            }
            setUpdatedUser(currUser);
        }
    }, [userConfigModel]);

    const handleChange = (name) => (event) => {
        if (event && event.target) {
            setUpdatedUser(prevState => { return { ...prevState, [name]: event.target.value } });
        } else {
            setUpdatedUser(prevState => { return { ...prevState, [name]: event } });
        }
    };

    const isValid = () => {
        let ret = true;
        const error = {};
        if (!updatedUser.username || updatedUser.username.trim().length === 0) {
            error.username = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        if (!updatedUser.email) {
            error.email = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        if (!updatedUser.first_name) {
            error.first_name = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        if (!updatedUser.last_name) {
            error.last_name = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        if (updatedUser.active === null || updatedUser.active === undefined) {
            error.last_name = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        if (!updatedUser.role) {
            error.role = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }

        setError(error);
        console.log('error', error);
        return ret;
    }

    console.log("updatedUser.role", updatedUser.role);


    return (

        <Dialog
            maxWidth="md"
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    height: '90%',
                    margin: 1,
                },
            }}
            open={userConfigModel.open}
            onClose={handleClose}
        >

            <DialogTitle sx={{
                height: '40px',
                paddingLeft: 5,
                paddingRight: 5,
                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                width: '100%',
            }}>
                <>
                    <Typography variant='h6'
                        sx={{ color: (theme) => theme.palette.background.contrastText }}>{userConfigModel.isNew ? t('CREATE_USER') : t('UPDATE_USER')}</Typography>
                </>
            </DialogTitle>


            <DialogContent>

                <FormFields>
                    <form autoComplete='off'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography align="left" variant='h6'
                                    sx={{ paddingBottom: 5 }}>{t('Account')}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container rowSpacing={0.5} columnSpacing={2}>
                                    <Grid item xs={12} sx={{ marginTop: 1 }}>
                                        <Typography align="left" variant='body2'>{t('USERNAME')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <StyledTextInput
                                            entity={updatedUser}
                                            error={error}
                                            propName={"username"}
                                            handleChange={handleChange}
                                            disabled={!userConfigModel.isNew} />
                                    </Grid>

                                    <Grid item xs={12} sx={{ marginTop: 1 }}>
                                        <Typography align="left" variant='body2'>{t('EMAIL')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <StyledTextInput
                                            entity={updatedUser}
                                            error={error}
                                            propName={"email"}
                                            handleChange={handleChange} />
                                    </Grid>

                                    <Grid item xs={6} sx={{ marginTop: 1 }}>
                                        <Typography align="left" variant='body2'>{t('FIRST_NAME')}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={{ marginTop: 1 }}>
                                        <Typography align="left" variant='body2'>{t('LAST_NAME')}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <StyledTextInput
                                            entity={updatedUser}
                                            error={error}
                                            propName={"first_name"}
                                            handleChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <StyledTextInput
                                            entity={updatedUser}
                                            error={error}
                                            propName={"last_name"}
                                            handleChange={handleChange} />
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12}>
                                <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />
                            </Grid>

                            <Grid item xs={4}>
                                <Typography align="left" variant='h6'
                                    sx={{ paddingBottom: 5 }}>{t('ACTIVE_USER')}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    fullWidth
                                    id="active"
                                    value={updatedUser.active ? "Y" : "N"}
                                    onChange={(event) => handleChange("active")(event.target.value === "Y")}
                                >
                                    <MenuItem value={"Y"}>{t("YES")}</MenuItem>
                                    <MenuItem value={"N"}>{t("NO")}</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>


                            <Grid item xs={12}>
                                <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />
                            </Grid>

                            <Grid item xs={4}>
                                <Typography align="left" variant='h6'
                                    sx={{ paddingBottom: 5 }}>{t('ROLE')}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    fullWidth
                                    id="role"
                                    value={updatedUser.role || ''}
                                    onChange={handleChange("role")}
                                >
                                    <MenuItem value={USER_ROLE_READER}>{t(USER_ROLE_READER)}</MenuItem>
                                    <MenuItem value={USER_ROLE_CONTRIBUTOR}>{t(USER_ROLE_CONTRIBUTOR)}</MenuItem>
                                    <MenuItem value={USER_ROLE_ADMINISTRATOR}>{t(USER_ROLE_ADMINISTRATOR)}</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>

                            {!updatedUser.password && <>
                                <Grid item xs={4}>
                                    <Typography align="left" variant='h6'
                                        sx={{ paddingBottom: 5 }}>{t('PASSWORD')}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Button
                                        sx={{
                                            //width: '150px', 
                                            margin: 1, 
                                            color: 'primary.contrastText', 
                                            backgroundColor: 'background.grey',
                                        }}
                                        variant="contained"
                                        onClick={() => {
                                            handleChange("password")(generateRandomString(20));
                                            setPasswordCopied(false);
                                        }}
                                    >{t('RESET_PASSWORD')} </Button>
                                </Grid>
                            </>}

                            {!!updatedUser.password && <Grid item xs={12}>
                                <Grid container
                                    rowSpacing={1}
                                    columnSpacing={1}
                                    sx={{ backgroundColor: 'secondary.light2', borderRadius: 1, padding: 2 }}
                                >
                                    <Grid item xs={12}>
                                        <Typography align="left" variant='h6' >{t('PASSWORD')}</Typography>
                                    </Grid>
                                    <Grid item xs={0.5}>
                                        <InfoIcon sx={{ color: 'background.grey600' }} />
                                    </Grid>
                                    <Grid item xs={11.5}>
                                        <Typography align="left" variant='h6'
                                            sx={{ color: 'background.grey600', padding: 'auto' }}>{t('COPY_PASSWORD_AND_SEND_TO_USER')}</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <OutlinedInput variant="outlined"
                                            fullWidth
                                            disabled
                                            id={"password"}
                                            value={updatedUser.password || ''}
                                            sx={{
                                                borderWidth: 0,
                                                borderRadius: 15,
                                                color: 'black !important',
                                                backgroundColor: "white",
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderWidth: 0,
                                                }
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => {
                                                            handleChange("password")(generateRandomString(20));
                                                            setPasswordCopied(false);
                                                        }}
                                                        edge="end"
                                                    >
                                                        <RefreshIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            sx={{
                                                width: '150px', 
                                                color: 'info.contrastText', 
                                                backgroundColor: 'primary.third',
                                                padding: "0.5rem",
                                                borderRadius: 15,
                                            }}
                                            variant="contained"
                                            onClick={() => { navigator.clipboard.writeText(updatedUser.password); setPasswordCopied(true); }}
                                        >{passwordCopied ? (<DoneIcon />) : t('COPY')} </Button>
                                    </Grid>
                                </Grid>
                            </Grid>}
                        </Grid>
                    </form>
                </FormFields>

            </DialogContent>

            <DialogActions
                sx={{
                    padding: 0, height: 100, width: '100%',
                }}>
                <Button
                    disabled={saving}
                    sx={{
                        width: '150px', margin: 1, color: 'primary.contrastText', backgroundColor: 'background.grey',
                    }}
                    variant="contained"
                    onClick={handleClose}
                >{t('CANCEL')} </Button>


                <Button
                    sx={{
                        width: '150px', margin: 1,
                        backgroundColor: 'primary.third',
                        color: 'primary.selection',
                    }}
                    variant="contained"
                    disabled={saving}
                    loading={saving}
                    onClick={() => {
                        const ret = isValid();
                        if (ret) {
                            let promise;
                            if (userConfigModel.isNew) {
                                promise = addUser(updatedUser);
                            } else {
                                promise = updateUser(updatedUser);
                            }
                            promise.then(handleClose);
                        }
                    }}
                >{saving && <CircularProgress size={24}/>} {!saving && t('SAVE')}</Button>

            </DialogActions>
        </Dialog>

    );
}




UserUpdate.propTypes = {};

const mapStateToProps = (store) => ({
    isAdding: store.userData.isAdding,
    isUpdating: store.userData.isUpdating,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addUser,
    updateUser,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserUpdate);
