import * as React from 'react';
import { Alert, Button, Card, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PATHS } from "../../constants";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { useSetState } from 'react-use';
import Joyride, { CallBackProps, STATUS, Step, EVENTS, ACTIONS } from 'react-joyride';
import { useTheme } from '@emotion/react';
import { alpha } from "@mui/material/styles";

const STORAGE_KEY = "basic_tour_complete";

const AppTour = ({ documentIndexes }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const tourComplete = localStorage.getItem(STORAGE_KEY) === 'true';

    const [{ complete, stepIndex, run, steps }, setTourState] = useSetState({
        complete: tourComplete,
        run: !tourComplete,
        steps: [
            {
                title: t("START_TUTORIAL"),
                content: t("START_TUTORIAL_DESCRIPTION"),
                placement: 'center',
                target: 'body',
                path: `/${process.env.REACT_APP_PATH}/`,
            },
            {
                content: t("TUTORIAL_FAVORITE_BINDERS"),
                target: '#favorite-binders',
                placement: 'right',
                path: `/${process.env.REACT_APP_PATH}/`,
            },
            {
                content: t("TUTORIAL_QUERY_HISTORY"),
                target: '#query-history',
                placement: 'left',
                path: `/${process.env.REACT_APP_PATH}/`,
            },
            {
                content: t("TUTORIAL_HOME_BUTTON"),
                target: '#home-button',
                placement: 'right',
                path: `/${process.env.REACT_APP_PATH}/`,
            },
            {
                content: t("TUTORIAL_BINDERS_BUTTON"),
                target: '#binders-button',
                placement: 'right',
                path: `/${process.env.REACT_APP_PATH}/`,
            },
            {
                content: t("TUTORIAL_BINDERS_LIST"),
                target: '#binders-list',
                placement: 'right',
                path: `/${process.env.REACT_APP_PATH}/`,
            },
            {
                content: t("TUTORIAL_ARCHIVE_BUTTON"),
                target: '#archive-button',
                placement: 'right',
                path: `/${process.env.REACT_APP_PATH}/`,
            },
            {
                content: t("TUTORIAL_LOGOUT_BUTTON"),
                target: '#logout-button',
                placement: 'top',
                path: `/${process.env.REACT_APP_PATH}/`,
            },
            {
                content: t("TUTORIAL_ALL_BINDERS_LIST"),
                target: 'body',
                placement: 'center',
                path: `/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}`,
            },
            {
                content: t("TUTORIAL_SEARCH_BINDERS_BUTTON"),
                target: '#search-index-input',
                placement: 'left',
                path: `/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}`,
            },
            {
                content: t("TUTORIAL_ADD_BINDER_BUTTON"),
                target: '#add-index-button',
                placement: 'left',
                path: `/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}`,
            },
            {
                content: t("TUTORIAL_BINDER_MENU"),
                target: '.document-index-menu-0',
                placement: 'right',
                path: `/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}`,
            },
            {
                content: t("TUTORIAL_BINDER_FAVORITE"),
                target: '.document-index-favorite-0',
                placement: 'right',
                path: `/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}`,
            },
        ],
    });

    const handleJoyrideCallback = (data) => {
        const { status, action, index, lifecycle, type, route } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setTourState({ run: false });
        }

        if (
            type === EVENTS.STEP_BEFORE &&
            steps[index].path !== location.pathname
        ) {
            navigate(steps[index].path);
        } else if (type === EVENTS.TARGET_NOT_FOUND) {
            setTourState({ run: false });
        } else if ((type === EVENTS.TOUR_END && index === steps.length - 1) ||
            (type === EVENTS.TOUR_END && action === ACTIONS.SKIP)) {
            setTourState({ run: false, complete: true });
            localStorage.setItem(STORAGE_KEY, 'true');
        }

        console.log("handleJoyrideCallback", data, location, steps[index].path, complete);
    };

    const FIRST_INDEX_STEP = 11;

    React.useEffect(() => {
        if (documentIndexes !== null &&
            documentIndexes.length === 1 &&
            !complete &&
            location.pathname === steps[FIRST_INDEX_STEP].path &&
            !run) {
            setTourState({ run: true, stepIndex: FIRST_INDEX_STEP });
        }
    }, [location, documentIndexes]);

    return <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        stepIndex={stepIndex}
        locale={
            {
                back: t('TUTORIAL_BACK'),
                close: t('TUTORIAL_CLOSE'),
                last: t('TUTORIAL_LAST'),
                next: t('TUTORIAL_NEXT'),
                skip: t('TUTORIAL_SKIP'),
            }
        }
        styles={{
            options: {
                arrowColor: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.background.default,
                overlayColor: alpha(theme.palette.background.grey, 0.5),
                primaryColor: theme.palette.secondary.main,
                textColor: theme.palette.secondary.contrastText,
                width: 500,
                zIndex: 10000,
            },
            tooltip: {
                borderRadius: theme.shape.borderRadius,
            },
        }}

    />;
}

AppTour.propTypes = {
    user: PropTypes.object,
};

AppTour.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    documentIndexes: store.documentIndexData.documentIndexes,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppTour);

