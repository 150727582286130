import React from "react";

export const PATHS = {
    DOCUMENT_INDEXES: 'documentindexes',
    ARCHIVED_DOCUMENTS: 'documentindexes/archived',
    DOCUMENTS: 'documentindexes/:index_name',
    DOCUMENT_DETAIL: 'documentindexes/:index_name/:document_id',
    SETTINGS: 'settings/:tab_name',
}

