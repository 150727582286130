import types from '../actionTypes';

const INITIAL_STATE = {
    history: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SHOW_HISTORY:
            return {
                ...state,
                history: action.payload.history,
            };
        default:
            return state;
    }
};
