import React, { useEffect, useState } from 'react';
import { FormHelperText, OutlinedInput } from "@mui/material";



export const StyledTextInput = ({ error, propName, entity, handleChange, ...otherProps }) => {
    
    return (<>
        <OutlinedInput variant="outlined"
            error={!!error[propName]}
            fullWidth
            id={propName}
            value={entity[propName] || ''}
            onChange={handleChange(propName)}
            sx={{
                borderWidth: 0,
                backgroundColor: "background.grey",
                '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: 0,
                }
            }}
            {...otherProps}
        />
        {error[propName] && <FormHelperText error={true}>{error[propName]}</FormHelperText>}
    </>);
}

