import httpService from './http.service';


const getQueryHistory = () => httpService
    .get(`/query_history/`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    getQueryHistory,
};