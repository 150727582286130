import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingAllList: false,
    isFetchingSingle: false,
    isSearching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    errors: '',
    documents: [],
    documentAnswers: null,
    documentSingle: null,
    documentChatAnswers: null,

};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.DOCUMENT_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                documents: action.payload.documents,
            };
        case types.DOCUMENT_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };
        case types.DOCUMENT_CREATE_SUCCESS:
            return {
                ...state,
                isCreating: false,
                documents: [...state.documents, action.payload.document],
            };
        case types.DOCUMENT_CREATE_FAIL:
            return {
                ...state,
                isCreating: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_CREATE_REQUEST:
            return {
                ...state,
                isCreating: true,
            };
        case types.DOCUMENT_DELETE_SUCCESS:
            return {
                ...state,
                isDeleting: false,
                documents: state.documents.filter((document) => document.id !== action.payload.documentId),
            };
        case types.DOCUMENT_DELETE_FAIL:
            return {
                ...state,
                isDeleting: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_DELETE_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case types.DOCUMENT_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                documents: state.documents.map((document) => {
                    if (document.id === action.payload.document.id) {
                        return action.payload.document;
                    }
                    return document;
                }),
            };
        case types.DOCUMENT_UPDATE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_UPDATE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case types.DOCUMENT_SEARCH_SUCCESS:
            return {
                ...state,
                isSearching: false,
                documentAnswers: action.payload.documentAnswers,
            };
        case types.DOCUMENT_SEARCH_FAIL:
            return {
                ...state,
                isSearching: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_SEARCH_REQUEST:
            return {
                ...state,
                isSearching: true,
            };
        case types.DOCUMENT_CHAT_SUCCESS:
            return {
                ...state,
                isSearching: false,
                documentChatAnswers: action.payload.documentAnswers,
            };
        case types.DOCUMENT_CHAT_FAIL:
            return {
                ...state,
                isSearching: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_CHAT_REQUEST:
            return {
                ...state,
                isSearching: true,
            };
        case types.DOCUMENT_FETCH_SINGLE_SUCCESS:
            return {
                ...state,
                isFetchingSingle: false,
                documentSingle: action.payload.document,
            };
        case types.DOCUMENT_FETCH_SINGLE_FAIL:
            return {
                ...state,
                isFetchingSingle: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_FETCH_SINGLE_REQUEST:
            return {
                ...state,
                isFetchingSingle: true,
            };
        default:
            return state;
    }
};
