import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';

const initialState = {};
const enhancers = [];

const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
  const { devToolsExtension } = window;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composeFunction = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;

const composedEnhancers = composeFunction(
  applyMiddleware(...middleware),
  ...enhancers,
);

export default createStore(rootReducer(), initialState, composedEnhancers);
