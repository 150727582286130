import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingAllList: false,
    isFetchingFavoritesList: false,
    isFetchingArchivedList: false,
    isFetchingSingle: false,
    isDeleting: false,
    isCreating: false,
    isUpdating: false,
    errors: '',
    documentIndexSingle: null,
    documentIndexes: null,
    favoriteDocumentIndexes: null,
    archivedDocumentIndexes: null,
    isFetchingUserList: false,
    documentIndexUsers: null,
    isAddingOrModifyingUser: false,
    isUpdatingFavorite: false,
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.DOCUMENT_INDEX_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                documentIndexes: action.payload.documentIndexes,
            };
        case types.DOCUMENT_INDEX_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };

        case types.DOCUMENT_INDEX_FETCH_SINGLE_SUCCESS:
            return {
                ...state,
                isFetchingSingle: false,
                documentIndexSingle: action.payload.documentIndex,
            };
        case types.DOCUMENT_INDEX_FETCH_SINGLE_FAIL:
            return {
                ...state,
                isFetchingSingle: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_FETCH_SINGLE_REQUEST:
            return {
                ...state,
                isFetchingSingle: true,
            };

        case types.DOCUMENT_INDEX_FETCH_FAVORITE_SUCCESS:
            return {
                ...state,
                isFetchingFavoritesList: false,
                favoriteDocumentIndexes: action.payload.documentIndexes,
            };
        case types.DOCUMENT_INDEX_FETCH_FAVORITE_FAIL:
            return {
                ...state,
                isFetchingFavoritesList: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_FETCH_FAVORITE_REQUEST:
            return {
                ...state,
                isFetchingFavoritesList: true,
            };

        case types.DOCUMENT_INDEX_FETCH_ARCHIVED_SUCCESS:
            return {
                ...state,
                isFetchingArchivedList: false,
                archivedDocumentIndexes: action.payload.documentIndexes,
            };
        case types.DOCUMENT_INDEX_FETCH_ARCHIVED_FAIL:
            return {
                ...state,
                isFetchingArchivedList: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_FETCH_ARCHIVED_REQUEST:
            return {
                ...state,
                isFetchingArchivedList: true,
            };

        case types.DOCUMENT_INDEX_CREATE_SUCCESS:
            return {
                ...state,
                isCreating: false,
                documentIndexes: [...state.documentIndexes, action.payload.documentIndex],
            };
        case types.DOCUMENT_INDEX_CREATE_FAIL:
            return {
                ...state,
                isCreating: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_CREATE_REQUEST:
            return {
                ...state,
                isCreating: true,
            };
        case types.DOCUMENT_INDEX_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
            };
        case types.DOCUMENT_INDEX_UPDATE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_UPDATE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case types.DOCUMENT_INDEX_DELETE_SUCCESS:
            return {
                ...state,
                isDeleting: false,
                documentIndexes: state.documentIndexes.filter((doc) => doc.id !== action.payload.documentIndexId),
            };
        case types.DOCUMENT_INDEX_DELETE_FAIL:
            return {
                ...state,
                isDeleting: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_DELETE_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };

        case types.DOCUMENT_INDEX_USERS_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingUserList: false,
                documentIndexUsers: action.payload.documentIndexUsers,
            };
        case types.DOCUMENT_INDEX_USERS_FETCH_FAIL:
            return {
                ...state,
                isFetchingUserList: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_USERS_FETCH_REQUEST:
            return {
                ...state,
                isFetchingUserList: true,
            };

        case types.DOCUMENT_INDEX_USER_ADD_SUCCESS:
            return {
                ...state,
                isAddingOrModifyingUser: false,
                documentIndexUsers: [...state.documentIndexUsers, action.payload.accessRight],
            };
        case types.DOCUMENT_INDEX_USER_ADD_FAIL:
            return {
                ...state,
                isAddingOrModifyingUser: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_USER_ADD_REQUEST:
            return {
                ...state,
                isAddingOrModifyingUser: true,
            };

        case types.DOCUMENT_INDEX_USER_REMOVE_SUCCESS:
            return {
                ...state,
                isAddingOrModifyingUser: false,
                documentIndexUsers: state.documentIndexUsers.filter((right) => right.user.id !== action.payload.userId),
            };
        case types.DOCUMENT_INDEX_USER_REMOVE_FAIL:
            return {
                ...state,
                isAddingOrModifyingUser: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_USER_REMOVE_REQUEST:
            return {
                ...state,
                isAddingOrModifyingUser: true,
            };
            
        case types.DOCUMENT_INDEX_USER_ACCESS_RIGHTS_CHANGE_SUCCESS:
            return {
                ...state,
                isAddingOrModifyingUser: false,
                documentIndexUsers: state.documentIndexUsers.map((right) => {
                    if (right.user.id === action.payload.accessRight.user.id) {
                        return action.payload.accessRight;
                    }
                    return right;
                }),
            };
        case types.DOCUMENT_INDEX_USER_ACCESS_RIGHTS_CHANGE_FAIL:
            return {
                ...state,
                isAddingOrModifyingUser: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_USER_ACCESS_RIGHTS_CHANGE_REQUEST:
            return {
                ...state,
                isAddingOrModifyingUser: true,
            };
        
        case types.DOCUMENT_INDEX_FAVORITE_ADD_SUCCESS:
            return {
                ...state,
                isUpdatingFavorite: false,
                documentIndexes: state.documentIndexes.map((doc) => {
                    if (doc.id === action.payload.documentIndexId) {
                        return {
                            ...doc,
                            favorite: true,
                        };
                    }
                    return doc;
                }),
            };
        case types.DOCUMENT_INDEX_FAVORITE_ADD_FAIL:
            return {
                ...state,
                isUpdatingFavorite: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_FAVORITE_ADD_REQUEST:
            return {
                ...state,
                isUpdatingFavorite: true,
            };

        case types.DOCUMENT_INDEX_FAVORITE_REMOVE_SUCCESS:
            return {
                ...state,
                isUpdatingFavorite: false,
                documentIndexes: state.documentIndexes.map((doc) => {
                    if (doc.id === action.payload.documentIndexId) {
                        return {
                            ...doc,
                            favorite: false,
                        };
                    }
                    return doc;
                }),
            };
        case types.DOCUMENT_INDEX_FAVORITE_REMOVE_FAIL:
            return {
                ...state,
                isUpdatingFavorite: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_FAVORITE_REMOVE_REQUEST:
            return {
                ...state,
                isUpdatingFavorite: true,
            };
        
        case types.DOCUMENT_INDEX_ARCHIVE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                documentIndexes: state.documentIndexes !== null ? state.documentIndexes.filter((doc) => doc.id !== action.payload.documentIndex.id) : null,
                archivedDocumentIndexes: state.archivedDocumentIndexes !== null ? [...state.archivedDocumentIndexes, action.payload.documentIndex] : null,
            };
        case types.DOCUMENT_INDEX_ARCHIVE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_ARCHIVE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };

        case types.DOCUMENT_INDEX_UNARCHIVE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                documentIndexes: state.documentIndexes !== null ? [...state.documentIndexes, action.payload.documentIndex] : null,
                archivedDocumentIndexes: state.archivedDocumentIndexes !== null ? state.archivedDocumentIndexes.filter((doc) => doc.id !== action.payload.documentIndex.id) : null,
            };
        case types.DOCUMENT_INDEX_UNARCHIVE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };
        case types.DOCUMENT_INDEX_UNARCHIVE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        
        default:
            return state;
    }
};
