import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Button, Divider, Grid, OutlinedInput, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSetState } from "react-use";
import { updateUser } from "../../redux/actions/user";
import { Link } from "react-router-dom";
import { getChangePasswordURL } from "../../services/keycloak.service";

const ProfilePage = ({
    user,
    updateUser,
}) => {
    const { t } = useTranslation();
    const [updatedUser, setUpdatedUser] = useSetState({ ...user });
    const [isSaving, setIsSaving] = useState(false);

    const handleSaving = () => {
        setIsSaving(true);
        updateUser(updatedUser).finally(
            () => {
                setIsSaving(false);
            }
        );
    };

    return (
        <Grid container rowSpacing={2} columnSpacing={2} sx={{ paddingTop: 4 }}>
            <Grid item xs={12}>
                <Typography align="left" variant="h5">
                    {t("USER")} {user.username}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />
            </Grid>
            <Grid item xs={2}>
                <Typography align="left" variant='body1' sx={{ fontWeight: 'bold' }}>{t('Account')}</Typography>
            </Grid>

            <Grid item xs={2}>
                <Typography align="left" variant="body1">
                    {t("FIRST_NAME")}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography align="left" variant="body1">
                    {t("LAST_NAME")}
                </Typography>
            </Grid>
            <Grid item xs={6}></Grid>

            <Grid item xs={2}></Grid>
            <Grid item xs={2}>
                <OutlinedInput variant="outlined" fullWidth value={updatedUser.first_name}
                    sx={{
                        borderWidth: 0,
                        backgroundColor: "background.grey",
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderWidth: 0,
                        }
                    }}
                    onChange={(event) => setUpdatedUser({ first_name: event.target.value })}
                />
            </Grid>

            <Grid item xs={2}>
                <OutlinedInput variant="outlined" fullWidth value={updatedUser.last_name}
                    sx={{
                        borderWidth: 0,
                        backgroundColor: "background.grey",
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderWidth: 0,
                        }
                    }}
                    onChange={(event) => setUpdatedUser({ last_name: event.target.value })}
                />
            </Grid>
            <Grid item xs={2} sx={{alignContent: "start", textAlign: "start", marginLeft: 2}}>
                <Button
                    id="save-profile-button"
                    sx={{
                        width: '150px'
                    }}
                    color="secondary"
                    variant="contained"
                    disabled={isSaving}
                    onClick={() => handleSaving()}>{t("SAVE")}</Button>
            </Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={12}>
                <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />
            </Grid>

            <Grid item xs={2} sx={{alignContent: "start", textAlign: "start"}}>
                <Link to={getChangePasswordURL()}>
                    <Button
                        id="change-password-button"
                        sx={{
                            //width: '150px', 
                            margin: 1,
                            backgroundColor: "primary.third",
                            color: "info.contrastText"
                        }}
                        variant="contained">{t("CHANGE_PASSWORD")}</Button>
                </Link>
            </Grid>
        </Grid>
    )
}

ProfilePage.propTypes = {
    user: PropTypes.object,
};

ProfilePage.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUser,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfilePage);