// Capitalization of string
import dayjs from "dayjs";

export const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

function invertColor(hex) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}


export const dayjsToTimeString = (date, locale) => {
    return dayjs(date).locale(locale).format('DD.MM.YY');
}

export const isIdontKnowAnswer = (answer) => {
    if (answer.init_parameters.data === null) {
        return true;
    }
    return false;
}

export const generateRandomString = (maxLength, minSmallChars = 1, minCapitalChars = 1, minNumbers = 1, minSpecialCharacters = 1) => {
    minSmallChars = Math.max(0, minSmallChars);
    minCapitalChars = Math.max(0, minCapitalChars);
    minNumbers = Math.max(0, minNumbers);
    minSpecialCharacters = Math.max(0, minSpecialCharacters);
    
    const counts = [minSmallChars, minCapitalChars, minNumbers, minSpecialCharacters];

    let currentLength = counts.reduce((partialSum, a) => partialSum + a, 0);
    if (maxLength < (currentLength)) {
        throw new Error("Invalid arguments!");
    }

    const charsets = [
        "abcdefghijklmnopqrstuvwxyz",
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        "0123456789",
        "!@#$%^&*()_+:.,"
    ];

    let result = "";

    for (;currentLength < maxLength; currentLength++) {
        counts[Math.floor(Math.random() * 4)]++;
    }

    for (;currentLength > 0;) {
        let randomDivider = Math.floor(Math.random() * currentLength);
        let randomIndex = 0;
        for (;;) {
            if (randomDivider < counts[randomIndex]) {
                break;
            }
            randomDivider -= counts[randomIndex];
            randomIndex++;
        }

        result += charsets[randomIndex][Math.floor(Math.random() * charsets[randomIndex].length)]

        counts[randomIndex]--;
        currentLength--;
    }

    return result;
}
