import * as React from 'react';
import { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button, Card, CardContent, Divider, Grid, Rating, styled } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Paper from "@mui/material/Paper";
import { useTheme } from '@emotion/react';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PATHS } from '../../constants';

const ItemGrid = styled(Grid)({
    textAlign: 'center'
});

/*const StyledGrid = styled(Grid)({
    borderRadius: '10px',
    padding: '10px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});*/

const StyledContentBox = styled(Grid)({
    backgroundColor: '#f5f5f5',
    padding: '10px',
    borderRadius: '10px',
    marginTop: '10px'
});

const IDonNotKnowCard = ({ score }) => {
    return (
        <Grid container spacing={2} alignItems="center" justifyContent="center" direction="row">
            <Grid item xs={1}>
                <Card sx={{ height: '100px' }}>
                    <CardContent>
                        <Typography variant="h3" gutterBottom>
                            {`${(score * 100).toFixed(0)}%`}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={11}>
                <Card sx={{ height: '100px' }}>
                    <CardContent
                        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <ReportProblemIcon />
                        <Typography variant="body1" component='div'>
                            I don't know. Please try to reformulate your question
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

const CONTEXT_WINDOW_SIZE = 150;

/**
 * Displays a single answer card with short context, probability and document name.
 * @param {*} param0
 */
export default function AnswerCard({ answer, threshold }) {
    const [showDocument, setShowDocument] = useState(false);
    const theme = useTheme();
    const { index_name } = useParams();
    answer = answer.init_parameters

    if (answer.data === null) {
        return <IDonNotKnowCard score={answer.score} />
    }

    const document = answer.document;
    const contextWindowStart = Math.max(0, answer.document_offset.start - CONTEXT_WINDOW_SIZE / 2);
    const contextWindowEnd = Math.min(document.content.length, answer.document_offset.end + CONTEXT_WINDOW_SIZE / 2);
    const preContext = document.content.substring(contextWindowStart, answer.document_offset.start) + " ";
    const postContext = " " + document.content.substring(answer.document_offset.end, contextWindowEnd);

    const preDocument = answer.document_offset.start > 0 ? document.content.substring(0, answer.document_offset.start) + " " : "";
    const postDocument = answer.document_offset.end < document.content.length ? " " + document.content.substring(answer.document_offset.end) : "";

    const handleDocumentClick = () => {
        setShowDocument(!showDocument);
    }

    return (
        <Grid container spacing={2} alignItems="stretch" justifyContent="flex-start" direction="row">
            <Grid item xs={1} >
                <Card elevation={0} sx={{ height: '100%', minHeight: '100px', backgroundColor: answer.score > threshold ? 'secondary.light' : 'background.grey' }}>
                    <CardContent>
                        <Typography variant="h3" gutterBottom>
                            {`${(answer.score * 100).toFixed(0)}%`}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={11}>
                <Card elevation={1} sx={{ height: '100%', minHeight: '100px', textAlign: "left" }}>
                    <CardContent>
                        <Grid container spacing={2} alignItems="center" justifyContent="flex-start" direction="row">
                            <Grid item xs={12}>
                                <Typography variant="h6" component="div" sx={{ marginBottom: 1, display: 'inline-block' }}>
                                    {document.meta.name}
                                </Typography>
                                <Link target="_blank" to={`/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}/${index_name}/${document.meta.doc_id}`} style={{ overflowX: "visible", color: "black" }}>
                                    <OpenInNewIcon sx={{fontSize: 18, display: 'inline-block', marginLeft: 1, cursor: 'pointer' }} />
                                </Link>
                            </Grid>
                            <Grid item xs={0.2} onClick={handleDocumentClick} sx={{cursor: 'pointer'}}>
                                {showDocument ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                            </Grid>
                            <Grid item xs={11.8} onClick={handleDocumentClick} sx={{cursor: 'pointer'}}>
                                <Typography variant="body1" component='div'>
                                    {showDocument ? preDocument : preContext}
                                    <div style={{ backgroundColor: theme.palette.secondary.light, display: "inline-block" }}>
                                        {answer.data}
                                    </div>
                                    {showDocument ? postDocument : postContext}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>

                </Card>
            </Grid>
        </Grid>
    );
}
