import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Button, Card, Chip, Grid, Grow, IconButton, InputAdornment, Menu, MenuItem, OutlinedInput, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSetState } from "react-use";
import { activateUser, deactivateUser, fetchUsers, updateUser } from "../../redux/actions/user";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { USER_ROLE_ADMINISTRATOR, USER_ROLE_CONTRIBUTOR, USER_ROLE_READER } from "../../utils/ListItem";
import Loading from "../../components/Loading";
import { Search } from "@mui/icons-material";
import UserUpdate from "../../components/UserUpdate";

const UserCard = ({
    user,
    handleUpdateUser,
    activateUser,
    deactivateUser,
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.preventDefault();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleActivationChange = () => {
        if (user.active) {
            deactivateUser(user.id);
        } else {
            activateUser(user.id);
        }
    };

    return (
        <>
            <Paper elevation={2} sx={{ height: '100%', minHeight: '100px', textAlign: "left", paddingRight: 2, paddingBottom: 1, opacity: user.active ? 1 : 0.3 }}>
                <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ margin: 1 }}>
                    <Grid item xs={3}>
                        <AccountCircleIcon sx={{ fontSize: 80 }} color="secondary" />
                    </Grid>
                    <Grid item xs={7}>
                        <Grid container alignItems="center" justifyContent="center" spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" sx={{textOverflow: "ellipsis", overflow: "hidden"}} >
                                    {user?.first_name} {user?.last_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" sx={{textOverflow: "ellipsis", overflow: "hidden"}} >
                                    {user?.email}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" sx={{
                                    maxWidth: "fit-content !important",
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    paddingTop: 0.5,
                                    paddingBottom: 0.5,
                                    borderRadius: 15,
                                    ...(user.role === USER_ROLE_ADMINISTRATOR && {
                                        color: 'secondary.third',
                                        backgroundColor: 'secondary.light',
                                    }),
                                    ...(user.role === USER_ROLE_CONTRIBUTOR && {
                                        color: 'primary.light',
                                        backgroundColor: 'primary.third',
                                    }),
                                    ...(user.role === USER_ROLE_READER && {
                                        color: 'background.grey600',
                                        backgroundColor: 'background.grey200',
                                    }),
                                }}>
                                    {user?.role}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} sx={{}}>
                        <IconButton aria-label="edit" size="small" onClick={handleClick} sx={{}}>
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>

            <Menu open={open} anchorEl={anchorEl} onClick={handleClose} onClose={handleClose}>
                <MenuItem onClick={handleUpdateUser}>{t("EDIT")}</MenuItem>
                <MenuItem onClick={handleActivationChange}>{user.active ? t("DEACTIVATE") : t("ACTIVATE")}</MenuItem>
            </Menu>
        </>
    );
}


const TeamPage = ({
    user,
    users,
    updateUser,
    isFetchingUsers,
    fetchUsers,
    activateUser,
    deactivateUser,
}) => {
    const { t } = useTranslation();
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [userConfigModel, setUserConfigModel] = useSetState({
        open: false,
        user: {},
        isNew: false,
    });

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        setFilteredUsers(users != null ? users.filter(usr => {
            return usr?.first_name?.toLowerCase().includes(filterValue.toLowerCase()) ||
                usr?.last_name?.toLowerCase().includes(filterValue.toLowerCase()) ||
                usr?.email?.toLowerCase().includes(filterValue.toLowerCase()) ||
                usr?.username?.toLowerCase().includes(filterValue.toLowerCase());
        }) : []);
    }, [users, filterValue]);

    const handleAddUser = () => {
        setUserConfigModel({
            open: true,
            user: { active: true, role: USER_ROLE_READER },
            isNew: true,
        });
    };

    const handleUpdateUser = (user) => {
        setUserConfigModel({
            open: true,
            user: user,
            isNew: false,
        })
    }

    const handleCloseUpdateUser = () => {
        setUserConfigModel({ open: false, user: {} });
    }

    if (user.role !== USER_ROLE_ADMINISTRATOR) {
        return <Loading />;
    }

    return (
        <>
            <Grid container spacing={2} sx={{ paddingTop: 4, paddingLeft: 0, paddingRight: 2 }}>
                <Grid item xl={1.2} xs={2}>
                    <Typography variant="h5" sx={{ textAlign: "start", whiteSpace: "nowrap" }}>
                        {t("YOUR_TEAM")}
                    </Typography>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: "start" }}>
                    <Chip sx={{ height: "100%" }} label={`${users != null ? users.filter(usr => usr.active).length : '-'} ${t('MEMBERS')}`} />
                </Grid>
                <Grid item xl={6.8} xs={6}>
                </Grid>
                <Grid item xs={2} sx={{ alignContent: "start" }}>
                    <Button
                        id="add-user-button"
                        sx={{
                            width: '150px'
                        }}
                        color="secondary"
                        variant="contained"
                        disabled={isSaving}
                        onClick={() => handleAddUser()}><PersonAddAltIcon /> {'\u00A0'} {t("ADD_USER")}</Button>
                </Grid>
                <Grid item xs={4}>
                    <OutlinedInput variant="outlined" fullWidth value={filterValue}
                        sx={{
                            borderWidth: 0,
                            backgroundColor: "background.grey",
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderWidth: 0,
                            }
                        }}
                        onChange={(event) => setFilterValue(event.target.value)}
                        endAdornment={(
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )}
                    />
                </Grid>
                <Grid item xs={8} sx={{ paddingBottom: 8 }}></Grid>

            </Grid>

            <Grid container spacing={2} sx={{ paddingBottom: 2, paddingLeft: 0, paddingRight: 2, overflow: "auto", maxHeight: "70vh" }}>
                {isFetchingUsers && <Loading />}
                {
                    !isFetchingUsers && filteredUsers.map((user, userIndex) =>
                        <Grow
                            in={true}
                            style={{ transformOrigin: '0 0 0' }}
                            timeout={Math.min(1000, userIndex * 200)}
                        >
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                                <UserCard
                                    user={user}
                                    handleUpdateUser={() => handleUpdateUser(user)}
                                    activateUser={activateUser}
                                    deactivateUser={deactivateUser} />
                            </Grid>
                        </Grow>)
                }
            </Grid>
            <UserUpdate
                userConfigModel={userConfigModel}
                handleClose={handleCloseUpdateUser}
            />
        </>
    )
}

TeamPage.propTypes = {
    user: PropTypes.object,
};

TeamPage.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    users: store.userData.users,
    isFetchingUsers: store.userData.isFetchingAllList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUser,
    fetchUsers,
    activateUser,
    deactivateUser,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TeamPage);